<template>
  <template v-if="!isLoginPage && !isRegisterPage && !isForgotPassword && !isLogout">
    <MainHeader />
    <MainSidebar />
    <div class="main-content d-flex flex-column transition overflow-hidden">
      <router-view />
      <MainFooter />
    </div>
  </template>
  <template v-else>
    <div class="pt-20 d-flex flex-column transition overflow-hidden">
      <router-view />
    </div>
  </template>
</template>

<script lang="ts">
import { defineComponent, watchEffect, computed } from "vue";
import { useRoute } from 'vue-router';
import stateStore from "@/stores/stateStore";

import MainHeader from "./components/Layouts/MainHeader.vue";
import MainSidebar from "./components/Layouts/MainSidebar.vue";
import MainFooter from "./components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "App",
  components: {
    MainHeader,
    MainSidebar,
    MainFooter,
  },
  mounted() {
    document.body.classList.add("bg-body-secondary");
  },
  setup() {
    const route = useRoute();
    const isLoginPage = computed(() => route.path === '/login');
    const isRegisterPage = computed(() => route.path === '/register');
    const isForgotPassword = computed(() => route.path === '/forgot-password');
    const isLogout = computed(() => route.path === '/logout');

    const stateStoreInstance = stateStore;

    watchEffect(() => {
      if (stateStoreInstance.open) {
        document.body.classList.remove("sidebar-show");
        document.body.classList.add("sidebar-hide");
      } else {
        document.body.classList.remove("sidebar-hide");
        document.body.classList.add("sidebar-show");
      }
    });
    return {
      isLoginPage,
      isRegisterPage,
      isForgotPassword,
      isLogout
    };
  },
});
</script>