<template>
  <div class="card mb-25 border-0 rounded-0 bg-white categories-box">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <div class="mb-15 mb-md-25 d-sm-flex align-items-center justify-content-between">
        <h5 class="card-title fw-bold mb-0">AI Precision Picks</h5>
        <router-link to="/ai-precision-picks"
          class="card-btn mt-10 mt-sm-0 d-inline-block fw-medium text-decoration-none transition ps-10 pe-10 pt-5 pb-5">
          All Picks
        </router-link>
      </div>
      <ul class="list ps-0 mb-0 list-unstyled">
        <li class="d-flex align-items-center justify-content-between" v-for="company in companies" :key="company.id">
          <div class="d-flex align-items-center">
            <img :src="require(`../../assets/images/${company.image}`)" width="44" height="44" class="rounded-1"
              alt="course-image" />
            <div class="title ms-15">
              <router-link to="/company-report"
                class="text-decoration-none text-black-emphasis fw-bold fs-md-15 fs-lg-16">
                {{ company.title }}
              </router-link>
              <span class="d-block text-muted fw-normal">
                {{ company.quantity }}
              </span>
            </div>
          </div>
          <router-link to="/company-report"
            class="link-btn d-inline-block transition text-center position-relative text-decoration-none">
            <i class="ph ph-arrow-right"></i>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import precisionPicks from "./precisionPicks.json";

export default defineComponent({
  name: "WidgetPrecisionPicks",
  data() {
    return {
      companies: precisionPicks,
    };
  },
});
</script>