<template>
  <div class="row justify-content-center mt-30">
    <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <h4 class="text-black fw-bold mb-0 text-center">
            Sign-In To Your Account!
          </h4>

          <form @submit.prevent="">
            <div v-if="errorMessage" class="alert alert-danger  alert-dismissible fs-md-15 fs-lg-16" role="alert">
              {{ errorMessage }}
              <button type="button" class="btn-close shadow-none" data-bs-dismiss="alert" aria-label="Close"></button>

            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Username
              </label>
              <input type="text" class="form-control shadow-none rounded-0 text-black" placeholder="Username"
                v-model="username" require />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Password
              </label>
              <input type="password" class="form-control shadow-none rounded-0 text-black" placeholder="**************"
                v-model="password" required />
            </div>
            <div class="d-flex align-items-center justify-content-between mb-15 mb-md-20">
              <template v-if="canAccessFeature('forgotPassword')">
                <router-link to="/forgot-password"
                class="forgot-password-btn fs-md-15 fs-lg-16 text-decoration-none position-relative text-primary">
                  Forgot Password?
                </router-link>
                <a href="https://dbt9q0ovf1s.typeform.com/to/wUvD8MOm" target="_blank" class="forgot-password-btn fs-md-15 fs-lg-16 text-decoration-none position-relative text-primary">
                  Request Access!
                </a>
              </template>
              <template v-else>
                <a href="https://dbt9q0ovf1s.typeform.com/to/mg3AYJ6Y" target="_blank" class="forgot-password-btn fs-md-15 fs-lg-16 text-decoration-none position-relative text-primary">
                  Forgot Password?
                </a>
                <a href="https://dbt9q0ovf1s.typeform.com/to/wUvD8MOm" target="_blank" class="forgot-password-btn fs-md-15 fs-lg-16 text-decoration-none position-relative text-primary">
                  Request Access!
                </a>
              </template>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success d-block w-100"
              @click.once="performLogin">
              Sign In Now
            </button>
            <template v-if="canAccessFeature('register')"> 
              <span class="d-block text-muted text-center mt-15 mt-md-30 mb-12 mb-md-20 fs-md-15 fs-lg-16">
                Don’t have an account?
              </span>
              <router-link to="/register"
                class="default-btn with-border transition fw-medium rounded-1 fs-md-15 fs-lg-16 d-block w-100 text-decoration-none text-center">
                Create Account
              </router-link>
            </template>
            <span
              class="d-block or text-muted text-center mt-15 mb-15 mt-md-20 mb-md-20 fs-md-15 fs-lg-16 position-relative z-1 lh-1">
              <span class="d-inline-block bg-white">Or</span>
            </span>
            <div class="d-flex justify-content-center align-items-center mt-4">
              <GoogleLogin :callback="performLoginWithGoogle" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/userStore';
import { canAccessFeature } from '@/utils/featuresUtils'
import { event } from 'vue-gtag'

export default {
  name: "LoginForm",
  setup() {
    const userStore = useUserStore();
    const router = useRouter();
    const username = ref('');
    const password = ref('');
    const isLoading = ref(false);
    const errorMessage = ref('');

    const redirectAfterLogin = () => {
      const redirectPath = localStorage.getItem('redirectAfterLogin') || '/';
      localStorage.removeItem('redirectAfterLogin');
      router.push(redirectPath);
    };

    const performLogin = async () => {
      isLoading.value = true;
      errorMessage.value = '';
      try {
        await userStore.login({ username: username.value, password: password.value });
        event('login', { method: 'Email' });
        redirectAfterLogin();
      } catch (error) {
        console.error('Login failed:', error);
        errorMessage.value = 'Login failed. Please try again.';
      } finally {
        isLoading.value = false;
      }
    };

    const performLoginWithGoogle = async (response) => {

      isLoading.value = true;
      errorMessage.value = '';

      const token = response.credential

      try {
        await userStore.loginWithGoogle(token);
        event('login', { method: 'Google' });
        redirectAfterLogin();
      } catch (error) {
        console.error('Google Login failed:', error);
        errorMessage.value = 'Google Login failed. Please try again.';
      } finally {
        isLoading.value = false;
      }
      
    };

    return {
      username,
      password,
      isLoading,
      errorMessage,
      canAccessFeature,
      performLogin,
      performLoginWithGoogle
    };
  },
};
</script>