import { createPinia } from 'pinia'
import { createApp, computed } from "vue";
import { createRouter, createWebHistory } from 'vue-router'
import App from "./App.vue";
import VueGtag from "vue-gtag"
import router from "./router";
import BootstrapVueNext from "bootstrap-vue-next";
import VueApexCharts from "vue3-apexcharts";
import { QuillEditor } from '@vueup/vue-quill'
import Vue3Prism from 'vue3-prism/lib/Vue3Prism.common.js'
import vue3GoogleLogin from 'vue3-google-login'
import { useUserStore } from '@/stores/userStore';

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "swiper/css";
import "swiper/css/bundle";
import 'flatpickr/dist/flatpickr.css';
import '@typeform/embed/build/css/sidetab.css'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import "vue3-prism/lib/Vue3Prism.css"

import "./assets/custom.scss";

const app = createApp(App);
const pinia = createPinia();
app.use(pinia);
app.use(router);
app.use(VueApexCharts);
app.use(BootstrapVueNext);
app.component('QuillEditor', QuillEditor)
.use(Vue3Prism)

const userStore = useUserStore();
userStore.checkAuthentication();

const componentKey = computed(() => userStore.isAuthenticated);

router.beforeEach((to, from, next) => {

    const publicPages = ['LoginPage', 'ForgotPasswordPage', 'EmailConfirmationPage', 'ResetPasswordPage']; 

    if (componentKey.value === false ) {
      localStorage.removeItem('researchSearches');
    }

    if (componentKey.value === false  && !publicPages.includes(to.name as string)) {
        localStorage.setItem('redirectAfterLogin', to.fullPath);
        localStorage.removeItem('researchSearches');
        next({ name: 'LoginPage' });
    } else if (componentKey.value === true && to.name === 'LoginPage') {
        next({ name: 'CVDashboard' });
    }
    else {
      next();
    }
  });

app.use(VueGtag, {
  config: { id: "G-F0QF14D601" }
}, router)

app.use(vue3GoogleLogin, {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
})

app.mount("#app");
