<template>
    <template v-if="company.status && company.status.includes('Meeting')">
        <span class="badge text-outline-success w-100">{{ company.status }}</span>
      </template>
      <template v-else-if="company.status && company.status.includes('New')">
        <span class="badge text-outline-success w-100" style="margin-right: 4px">{{ company.status }}</span>
      </template>
      <template v-else-if="company.status && company.status.includes('Queue')">
        <span class="badge bg-secondary w-100">{{ company.status }}</span>
      </template>
      <template v-else-if="company.status && company.status.includes('Pass')">
        <template v-if="company.status_reason !== false"> 
          <span class="badge text-outline-danger popover-content w-100" tabindex="0" data-bs-toggle="popover" data-bs-trigger="hover focus"
            :data-bs-content="company.status_reason">
            {{company.status}}
            <i class="flaticon-info align-middle"></i>
          </span>
        </template>
        <template v-else>test
          <span class="badge text-outline-danger w-100">{{ company.status }} </span>
        </template>
        
      </template>
      <template v-else-if="company.status">
        <span class="badge text-outline-info">{{ company.status }}</span>
      </template>
      <template v-else>
        <span class="badge text-outline-success">New</span>
      </template>
  </template>
  
  <script>
  import {companiesEmits} from '@/components/Companies/CompaniesUtils.js'

  export default {
    props: ['company'],
    emits: companiesEmits,
  };
  </script>