<template>
  <div class="card-body p-10">
    <ul class="settings-tabs ps-0 mb-0 list-unstyled">
      <li>
        <router-link to="/account-settings"
          class="fs-md-15 fs-lg-16 fw-medium text-decoration-none d-block w-100 rounded-1 text-center transition">
          Account Settings
        </router-link>
      </li>
      <li>
        <router-link to="/organization-settings"
          class="fs-md-15 fs-lg-16 fw-medium text-decoration-none d-block w-100 rounded-1 text-center transition">Org
          Settings
        </router-link>
      </li>
      <li>
        <router-link to="/settings-users"
          class="fs-md-15 fs-lg-16 fw-medium text-decoration-none d-block w-100 rounded-1 text-center transition">
          Users Settings
        </router-link>
      </li>
      <li>
        <router-link to="/settings-change-password"
          class="fs-md-15 fs-lg-16 fw-medium text-decoration-none d-block w-100 rounded-1 text-center transition">
          Change Password
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProfileLinks",
});

</script>