  <template>
    <div
    class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing"
  >
      <h4 class="mb-5 mb-sm-0 fw-bold">Company Research</h4>
      <nav>
        <div class="d-flex align-items-center justify-content-between" v-if="canAccessFeature('companyFeedback')">
          <h6 class="fw-medium text-black-emphasis mb-0">Research Feedback</h6>
          <div class="p-5 p-sm-10 p-md-15">
            <i class="flaticon flaticon-like fs-18 lh-1 position-relative top-2 me-3"></i>
            <i class="flaticon flaticon-dislike fs-18 lh-1 position-relative top-2"></i>
          </div>
        </div>
      </nav>
    </div>
    <CompanyDetails />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  import { canAccessFeature } from '@/utils/featuresUtils'; 
  import CompanyDetails from "../../components/Reports/Companies/CompanyDetails.vue";
  
  export default defineComponent({
    name: "CompanyReportPage",
    components: {
      CompanyDetails,
    },
    setup() {
      return {
        canAccessFeature
      }
    },
  });

  </script>