import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.company && _ctx.company.reputation_risks && _ctx.company.reputation_risks.flags && _ctx.company.reputation_risks.flags?.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.company.reputation_risks.flags, (flag, index) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            key: index,
            to: _ctx.getRouterLinkTo(flag),
            class: "text-bg-warning badge me-1 text-decoration-none"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getFlagText(flag)), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}