<template>
    <div class="alert alert-light p-40" role="alert" v-if="is_research_complete === false && is_streaming_complete == false && is_streaming == true">

        <h4 class="alert-heading fw-bold mb-30">Hold tight, I am researching {{ company.name }}!</h4>

        <h6 class="alert-heading fw-bold mb-30">Status Update: {{ company.research_status }}</h6>

        <p class=""><strong>My Tasks</strong></p>

        <div class="fs-lg-16 mb-30">
            <div class="d-flex">
                <div class="col-md-auto me-4">
                    <div class="d-flex align-items-center mb-2">
                        <div class="spinner-border text-primary spinner-border-sm me-3" role="status" v-if="company_research_data.INFO == null">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div v-else class="me-2">
                            ✅
                        </div>
                        <div :class="{'text-decoration-line-through': company_research_data.INFO !== null}">
                            Research Team
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="spinner-border text-primary spinner-border-sm me-3" role="status" v-if="company_research_data.NEWS == null">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div v-else class="me-2">
                            ✅
                        </div>
                        <div :class="{'text-decoration-line-through': company_research_data.NEWS !== null}">
                            Research Company News
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="spinner-border text-primary spinner-border-sm me-3" role="status" v-if="company_research_data.EREVIEWS == null">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div v-else class="me-2">
                            ✅
                        </div>
                        <div :class="{'text-decoration-line-through': company_research_data.EREVIEWS !== null}">
                            Research Employee Reviews
                        </div>
                    </div>
                </div>
                <div class="col-md-auto me-4">
                    <div class="d-flex align-items-center mb-2">
                        <div class="spinner-border text-primary spinner-border-sm me-3" role="status" v-if="company_research_data.UREVIEWS == null">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div v-else class="me-2">
                            ✅
                        </div>
                        <div :class="{'text-decoration-line-through': company_research_data.UREVIEWS !== null}">
                            Research User Reviews
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                    <div class="spinner-border text-primary spinner-border-sm me-3" role="status" v-if="company_research_data.BIPOCBEN == null">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div v-else class="me-2">
                            ✅
                        </div>
                        <div :class="{'text-decoration-line-through': company_research_data.BIPOCBEN !== null}">
                            Research Ethics and Impact
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="spinner-border text-primary spinner-border-sm me-3" role="status" v-if="company_research_data.PROD == null">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div v-else class="me-2">
                            ✅
                        </div>
                        <div :class="{'text-decoration-line-through': company_research_data.PROD !== null}">
                            Research Products
                        </div>
                    </div>
                </div>
                <div class="col-md-auto me-4">
                    <div class="d-flex align-items-center mb-2">
                        <div class="spinner-border text-primary spinner-border-sm me-3" role="status" v-if="company_research_data.TARAUD == null">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div v-else class="me-2">
                            ✅
                        </div>
                        <div :class="{'text-decoration-line-through': company_research_data.TARAUD !== null}">
                            Research Target Audience
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="spinner-border text-primary spinner-border-sm me-3" role="status" v-if="company_research_data.PRODCOST == null">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div v-else class="me-2">
                            ✅
                        </div>
                        <div :class="{'text-decoration-line-through': company_research_data.PRODCOST !== null}">
                            Research Cost
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="spinner-border text-primary spinner-border-sm me-3" role="status" v-if="company_research_data.LEGAL == null">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div v-else class="me-2">
                            ✅
                        </div>
                        <div :class="{'text-decoration-line-through': company_research_data.LEGAL !== null}">
                            Research Legal
                        </div>
                    </div>
                </div>
            </div>
           
                
                
                

            
        
        </div>
      
            
        <div class="progress" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar progress-bar-striped progress-bar-animated" :style="{ width: progress_bar + '%' }"></div>
        </div>

    </div>

    <div :class="{'alert alert-primary p-40': progress_bar >= 95, 'alert alert-light p-40': progress_bar < 95}" role="alert" v-if="is_research_complete === true && is_streaming == true && is_streaming_complete == false">

        <template v-if="progress_bar < 95">
            <h4 class="alert-heading fw-bold mb-10">Research is Complete ✅✅✅</h4>
        
            <h5 class="alert-heading fw-bold mb-15">I am going to check my notes before writing!</h5>

            <h6 class="alert-heading fw-bold mb-30">I am... {{ company.research_status }}</h6>

            <p class="fs-md-15 fs-lg-16 mt-5">Stay tuned, the report is on its way!</p>

        </template>
        <template v-else>
            <h4 class="alert-heading fw-bold mb-10">Time to start writing!</h4>

            <h6 class="alert-heading fw-bold mb-30">Right now I am... {{ company.research_status }}</h6>

            <p class="fs-md-15 fs-lg-16 mt-5">Report should be done in a few moments!</p>

        </template>

            
        <div class="progress mt-40" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar progress-bar-striped progress-bar-animated" :style="{ width: progress_bar + '%' }"></div>
        </div>

    </div>

    <div class="alert alert-success alert-dismissible p-40" role="alert" v-if="is_research_complete === true && is_streaming_complete === true">
     
        <h4 class="alert-heading fw-bold mb-10">The report is done! 🎉🎉🎉</h4>
            
        <p class="fs-md-15 fs-lg-16 mt-20">Break's over, time to get back at it! 🛠️</p>
            
        <div class="progress mt-40" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar bg-success" style="width: 100%"></div>
        </div>

        <button type="button" class="btn-close shadow-none" data-bs-dismiss="alert" aria-label="Close"></button>

    </div>

</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CompanyReportProgress',
  props: {
    company: {
      type: Object,
      required: true,
    },
    company_research_data: {
      type: Object,
      required: true,
    },
    progress_bar: {
      type: Number,
      required: true,
    },
    is_research_complete: {
      type: Boolean,
      required: true,
    },
    reportVisible: {
      type: Boolean,
      required: true,
    },
    is_streaming: {
        type: Boolean,
        required: true,
    },
    is_streaming_complete: {
        type: Boolean,
        required: true,
    }
  },
});
</script>