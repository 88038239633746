<template>
    {{ text }}
 </template>
 
 <script>
 import {companiesEmits} from '@/components/Companies/CompaniesUtils.js'

 export default {
   props: ['text'],
   emits: companiesEmits,
 };
 </script>