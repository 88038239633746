import { createWebHistory, createRouter } from "vue-router";

import DashboardPage from "@/pages/Dashboard/DashboardPage.vue";
import ErrorPage from "@/pages/ErrorPage.vue";
import InboundPage from "@/pages/Inbound/InboundPage.vue";
import PrecisionPicksPage from "@/pages/PrecisionPicks/PrecisionPicksPage.vue";
import UnderConstructionPage from "@/pages/UnderConstructionPage.vue";
import LoginPage from "@/pages/Authentication/LoginPage.vue";
import RegisterPage from "@/pages/Authentication/RegisterPage.vue";
import ForgotPasswordPage from "@/pages/Authentication/ForgotPasswordPage.vue";
import ResetPasswordPage from "@/pages/Authentication/ResetPasswordPage.vue";
import LogoutPage from "@/pages/Authentication/LogoutPage.vue";
import SearchPage from "@/pages/Search/SearchPage.vue";
import CompanyReportPage from "@/pages/Reports/CompanyReportPage.vue";
import AccountSettingsPage from "@/pages/Settings/AccountSettingsPage.vue";
import OrganizationSettingsPage from "@/pages/Settings/OrganizationSettingsPage.vue";
import ChangePasswordPage from "@/pages/Settings/ChangePasswordPage.vue";
import DeleteAccountPage from "@/pages/Settings/DeleteAccountPage.vue";
import UsersSettingsPage from "@/pages/Settings/UsersSettingsPage.vue";
import NotificaitonsPage from "@/pages/Notifications/NotificationsPage.vue";
import { canAccessFeature } from "@/utils/featuresUtils"

const routes = [
  {
    path: "/",
    name: "CVDashboard",
    component: DashboardPage,
  },
  {
    path: "/search",
    name: "SearchPage",
    component: SearchPage,
  },
  {
    path: "/company-report/:companyId?",
    name: "CompanyReportPage",
    component: CompanyReportPage,
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/logout",
    name: "LogoutPage",
    component: LogoutPage,
  },
  {
    path: "/under-construction",
    name: "UnderConstructionPage",
    component: UnderConstructionPage,
  },
  {
    path: "/ai-precision-picks",
    name: canAccessFeature('aiPrecisionPicks') ? "PrecisionPicks" : "ErrorPage",
    component: canAccessFeature('aiPrecisionPicks') ? PrecisionPicksPage : ErrorPage,
  },
  {
    path: "/inbound",
    name: canAccessFeature('inboundList') ? "Inbound" : "ErrorPage",
    component: canAccessFeature('inboundList') ? InboundPage : ErrorPage,
  },
  {
    path: "/register",
    name: canAccessFeature('register') ? "RegisterPage" : "ErrorPage",
    component: canAccessFeature('register') ? RegisterPage : ErrorPage,
  },
  {
    path: "/forgot-password",
    name: canAccessFeature('forgotPassword') ? "ForgotPasswordPage" : "ErrorPage",
    component: canAccessFeature('forgotPassword') ? ForgotPasswordPage : ErrorPage,
  },
  {
    path: "/reset-password",
    name: canAccessFeature('resetPassword') ? "ResetPasswordPage" : "ErrorPage",
    component: canAccessFeature('resetPassword') ? ResetPasswordPage : ErrorPage,
  },
  {
    path: "/account-settings",
    name: canAccessFeature('accountSettings') ? "AccountSettingsPage" : "ErrorPage",
    component: canAccessFeature('accountSettings') ? AccountSettingsPage : ErrorPage,
  },
  {
    path: "/organization-settings",
    name: canAccessFeature('organizationSettings') ? "OrganizationSettingsPage" : "ErrorPage",
    component: canAccessFeature('organizationSettings') ? OrganizationSettingsPage : ErrorPage,
  },
  {
    path: "/settings-change-password",
    name: canAccessFeature('changePassword') ? "ChangePasswordPage" : "ErrorPage",
    component: canAccessFeature('changePassword') ? ChangePasswordPage : ErrorPage,
  },
  {
    path: "/settings-delete-account",
    name: canAccessFeature('deleteAccount') ? "DeleteAccountPage" : "ErrorPage",
    component: canAccessFeature('deleteAccount') ? DeleteAccountPage : ErrorPage,
  },
  {
    path: "/settings-users",
    name: canAccessFeature('usersSettings') ? "UsersSettingsPage" : "ErrorPage",
    component: canAccessFeature('usersSettings') ? UsersSettingsPage : ErrorPage,
  },
  {
    path: "/notifications",
    name: canAccessFeature('notifications') ? "NotificaitonsPage" : "ErrorPage",
    component: canAccessFeature('notifications') ? NotificaitonsPage : ErrorPage,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "ErrorPage",
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes: routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;