import { defineStore } from 'pinia';
import { userService } from '@/api/userService';
import { ref } from 'vue';

function isTokenExpired(token) {
  // Check if the token is a string
  if (typeof token !== 'string' || !token) return true;
  try {
    const payloadBase64 = token.split('.')[1];
    const decodedJson = atob(payloadBase64);
    const decoded = JSON.parse(decodedJson);
    const exp = decoded.exp;
    const now = Date.now() / 1000;
    return exp < now;
  } catch (e) {
    // If an error occurs during decoding, assume the token is invalid
    console.error('Error checking token expiration:', e);
    return true;
  }
}

// User store
export const useUserStore = defineStore('user', {
  state: () => ({
    accessToken: localStorage.getItem('access_token') || null,
    refreshToken: localStorage.getItem('refresh_token') || null,
    username: localStorage.getItem('username') || null,
    userId: localStorage.getItem('user_id') || null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.accessToken,
  },
  actions: {
    login(user) {
      return userService.loginUser(user)
      .then(async (response) => {
        const { access, refresh } = response.data;
        this.setTokens(access, refresh);
        this.username = user.username;
        if (this.accessToken && this.refreshToken) {
          localStorage.setItem('username', user.username);
          
          const authResponse = await userService.getMe()

          const status = authResponse.status
          const data = authResponse.data

          if (status === 200) {
            this.username = data.username;
            this.userId = data.id;

            localStorage.setItem('username', data.username);
            localStorage.setItem('user_id', data.id);
            localStorage.setItem('user',  JSON.stringify(data))

            const userProfile = await userService.getProfile()

            if (userProfile.status === 200 )
              localStorage.setItem('user_profile',  JSON.stringify(userProfile.data))
          }
    
          // Save user login record
          userService.saveLoginRecord()
        } 
      })
      .catch(error => {
        console.error('Login error:', error);
        throw error;
      });
    },
    loginWithGoogle(token) {
      return userService.loginGoogle(token)
      .then(async (response) => {
        const { access, refresh } = response.data;
        this.setTokens(access, refresh);

        // Not DRY!!
        if (this.accessToken && this.refreshToken) {
          const authResponse = await userService.getMe()
          
          const status = authResponse.status
          const data = authResponse.data
          
          if (status === 200) {
              this.username = data.username;
              this.userId = data.id;

              localStorage.setItem('username', data.username);
              localStorage.setItem('user_id', data.id);
              localStorage.setItem('user',  JSON.stringify(data))
              
              const userProfile = await userService.getProfile()
              if (userProfile.status === 200 )             
                localStorage.setItem('user_profile',  JSON.stringify(userProfile.data))
          }

          // Save user login record
          userService.saveLoginRecord()
        } 
      })
      .catch(error => {
        console.error('Login error:', error);
        throw error;
      });
    },
    logout() {
      this.clearTokens();
    },
    getProfile() {
      
      const userProfileJson = localStorage.getItem('user_profile');

      if (userProfileJson) {
        try {
          const userProfileArray = JSON.parse(userProfileJson);
          return userProfileArray;
        } catch (error) {
          console.error('Error parsing user profile JSON:', error);
        }
      }
      this.clearTokens();
      return false

    },
    getUser() {
      const userJson = localStorage.getItem('user');
      if (userJson) {
        try {
          const userArray = JSON.parse(userJson);
          return userArray;
        } catch (error) {
          console.error('Error parsing user profile JSON:', error);
          return null;
        }
      }
      this.clearTokens();
      return false;
    },
    generateRefreshToken() {
      if (!this.refreshToken) {
        this.logout();
        return Promise.reject("No refresh token available.");
      }

      return userService.refreshToken(this.refreshToken)
      .then(response => {
        const { access } = response.data;
        this.setAccessToken(access);
        return access;
      })
      .catch(error => {
        console.error('Token refresh error:', error);
        this.logout();
        throw error;
      });
    },
    setTokens(accessToken, refreshToken) {
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('refresh_token', refreshToken);
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
    },
    setAccessToken(accessToken) {
      localStorage.setItem('access_token', accessToken);
      this.accessToken = accessToken;
    },
    setId(userId) {
      localStorage.setItem('user_id', userId);
      this.userId = userId;
    },
    clearTokens() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('username');
      localStorage.removeItem('user_id');
      localStorage.removeItem('user_profile');
      localStorage.removeItem('user');
      this.accessToken = null;
      this.refreshToken = null;
    },
    checkAuthentication() {
      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token')

      if (accessToken && !isTokenExpired(accessToken)) {
        this.accessToken = accessToken;
      } else if (refreshToken && !isTokenExpired(refreshToken)) {
        // If the access token is expired but the refresh token is not, refresh the token
        this.generateRefreshToken()
      } else {
        // If both tokens are expired or missing, clear them
        this.clearTokens();
      }
    },
    getValidAccessToken() {
      if (!isTokenExpired(this.accessToken)) {
        return Promise.resolve(this.accessToken);
      } else if (!isTokenExpired(this.refreshToken)) {
        return this.generateRefreshToken().then(() => this.accessToken);
      } else {
        this.clearTokens();
        return Promise.reject(new Error("No valid tokens available."));
      }
    },
    getUserId() {
      return this.userId;
    }
  }
});