<template>
  <span :class="alignmentBadgeClass(company.in_thesis, company.gap_closing_score)" v-if="company.in_thesis !== null ">
    <i v-if="company.gap_closing_score !== null" :class="alignmentIconClass(company.in_thesis, company.gap_closing_score)"></i> {{ alignmentText(company.in_thesis, company.gap_closing_score) }}
  </span>
</template>

<script>
import {companiesEmits} from '@/components/Companies/CompaniesUtils.js'

export default {
  props: ['company'],
  emits: companiesEmits,
  methods: {
    alignmentBadgeClass(alignment, score) {
      return {
        'badge w-100': true,
        'bg-secondary': score == null,
        'text-outline-success': alignment == true && score >= 7,
        'text-outline-primary': alignment == true && score < 7 && score >= 6.5,
        'text-outline-danger': alignment == false && score != null,
        'text-outline-warning': alignment == true && score < 6.5 && score != null,
      };
    },
    alignmentText(alignment, score) {
      if ( score == null ) return "In Queue"
      if ( alignment == true && score >= 7) return "On Target"
      if ( alignment == true && score < 7 && score >= 6.5) return "On the Fence"
      if ( alignment == true && score < 6.5 ) return "Not Gap-Closing"

      return "Outside Thesis"

    },
    alignmentIconClass(alignment, score) {
      return {
        'flaticon-taget align-middle': alignment == true && score >= 7,
        'ph ph-scales align-middle': alignment == true && score < 7 && score >= 6.5,
        'flaticon-cancel align-middle': alignment == false,
        'flaticon-warning align-middle': alignment == true && score < 6.5
      };
    }
  }
};
</script>