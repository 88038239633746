<template>
    <div class="submission-date">
        {{ formattedDate }}
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import {companiesEmits} from '@/components/Companies/CompaniesUtils.js'

export default defineComponent({
  name: "CompanySubmissionDateCell",
  props: ['company'],
  emits: companiesEmits,
  computed: {
    formattedDate(): string {
      const date = new Date(this.company.submission_date);
      return date.toLocaleDateString();
    }
  }
});
</script>