const featureFlags = {
    dashboard: process.env.VUE_APP_FEATURE_FLAG_DASHBOARD === 'true' ? true : false,
    notifications: process.env.VUE_APP_FEATURE_FLAG_NOTIFICATIONS === 'true' ? true : false,
    aiPrecisionPicks: process.env.VUE_APP_FEATURE_FLAG_AI_PRECISION_PICKS === 'true' ? true : false,
    accountSettings: process.env.VUE_APP_FEATURE_FLAG_ACCOUNT_SETTINGS === 'true' ? true : false,
    companyActions: process.env.VUE_APP_FEATURE_FLAG_COMPANY_ACTIONS === 'true' ? true : false,
    inboundList: process.env.VUE_APP_FEATURE_FLAG_INBOUND_LIST == 'true' ? true : false,
    inboundListActions: process.env.VUE_APP_FEATURE_FLAG_INBOUND_LIST_ACTIONS == 'true' ? true : false,
    companyFeedback: process.env.VUE_APP_FEATURE_FLAG_COMPANY_FEEDBACK === 'true' ? true : false,
    listFeedback: process.env.VUE_APP_FEATURE_FLAG_LIST_FEEDBACK === 'true' ? true : false,
    favorites: process.env.VUE_APP_FEATURE_FLAG_FAVORITES === 'true' ? true : false,
    customLists: process.env.VUE_APP_FEATURE_FLAG_CUSTOM_LISTS === 'true' ? true : false,
    forgotPassword: process.env.VUE_APP_FEATURE_FLAG_FORGOT_PASSWORD === 'true' ? true : false,
    resetPassword: process.env.VUE_APP_FEATURE_FLAG_RESET_PASSWORD === 'true' ? true : false,
    changePassword: process.env.VUE_APP_FEATURE_FLAG_CHANGE_PASSWORD === 'true' ? true : false,
    register: process.env.VUE_APP_FEATURE_FLAG_REGISTER === 'true' ? true : false,
};
  
export const canAccessFeature = (featureName) => {
    return featureFlags[featureName] || false;
};