<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-head pt-25 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
            <h2 class="card-title placeholder-glow mb-0"><span class="placeholder col-9"></span></h2>
        </div>
        <div
            class="card-body pt-0 pb-15 pb-sm-20 pb-md-25 pb-lg-30 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
            <h5 class="placeholder-glow"><span class="placeholder col-12"></span></h5>
            <h5 class="placeholder-glow"><span class="placeholder col-11"></span></h5>
            <h5 class="placeholder-glow"><span class="placeholder col-12"></span></h5>
            <h5 class="placeholder-glow"><span class="placeholder col-11"></span></h5>
            <h5 class="placeholder-glow"><span class="placeholder col-12"></span></h5>
            <h5 class="placeholder-glow"><span class="placeholder col-11"></span></h5>
        </div>
    </div>
</template>