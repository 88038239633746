<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div
            class="card-head bg-white d-md-flex align-items-center justify-content-between pt-25 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
            <div class="profile-info d-sm-flex align-items-center">
                <template v-if="!founder.profile_picture">
                    <span class="d-flex justify-content-center align-items-center bg-primary rounded me-2"
                        style="display: block; height: 50px; width: 50px">
                        <i class="flaticon-user-1 text-white" style="font-size:30px; line-height: 1;"></i>
                    </span>
                </template>
                <template v-else>
                    <img :src="founder.profile_picture" class="rounded me-2" alt="user" width="50px" height="50px" />
                </template>
                <h5 class="card-title fw-bold mb-0">
                    {{ founder.name }}
                    <template v-if="founder.title">({{ founder.title }})</template>
                </h5>
            </div>
            <div class="d-flex align-items-center mt-10 mt-md-0 fs-md-25 text-muted">
                <a v-if="founder.facebook_url" :href="founder.facebook_url" target="_blank"
                    class="ms-2 text-decoration-none">
                    <i class="icon ph-bold ph-facebook-logo"></i>
                </a>
                <a v-if="founder.twitter_url" :href="founder.twitter_url" target="_blank"
                    class="ms-2 text-decoration-none">
                    <i class="icon ph-fill ph-twitter-logo"></i>
                </a>
                <a v-if="founder.linkedin_url" :href="founder.linkedin_url" target="_blank"
                    class="ms-2 text-decoration-none">
                    <i class="icon ph-bold ph-linkedin-logo"></i>
                </a>
            </div>
        </div>
        <div
            class="card-body pt-0 pb-0 pb-sm-20 pb-md-25 pb-lg-30 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
            <template v-if="founder.summary">
                <p><strong>LinkedIn Bio:</strong> {{ founder.summary }}</p>
            </template>
            <div class="row d-flex align-items-start">
                <div class="col-6">
                    <template v-if="founder.address">
                        <p><strong>Location: </strong>{{ founder.address }}</p>
                    </template>

                    <template v-if="founder.experiences && founder.experiences.length > 0">

                        <h6>Experience</h6>
                        <ul>
                            <li v-for="(experience, expIndex) in founder.experiences" :key="expIndex">

                                <a v-if="experience.linkedin_url" :href="experience.linkedin_url"
                                    target="_blank"><strong>{{
                    experience.name }}</strong></a>
                                <strong v-else>{{ experience.name }}</strong>

                                <span v-if="experience.title"> - {{ experience.title }}</span>

                                <span v-if="experience.start_year">

                                    ({{ experience.start_year }} -

                                    <span>{{ experience.end_year === null ? 'Present' : experience.end_year }})</span>

                                </span>


                            </li>
                        </ul>

                    </template>

                    <template v-if="founder.education && founder.education.length > 0">
                        <h6>Education</h6>
                        <ul>
                            <li v-for="(experience, expIndex) in founder.education" :key="expIndex">
                                <a v-if="experience.linkedin_url" :href="experience.linkedin_url"
                                    target="_blank"><strong>{{
                    experience.name }}</strong></a>
                                <strong v-else>{{ experience.name }}</strong>

                                <span v-if="experience.title"> - {{ experience.title }}</span>

                                <span v-if="experience.start_year">

                                    ({{ experience.start_year }} -

                                    <span>{{ experience.end_year === null ? 'Present' : experience.end_year }})</span>

                                </span>


                            </li>
                        </ul>
                    </template>


                </div>
            </div>


        </div>
    </div>

</template>

<script>
export default {
    props: ['founder'],
};
</script>