<template>
    <template v-if="company.submission_attachments && company.submission_attachments != 'No Attachments Found'">
      <div class="d-flex justify-content-center">
        <div v-for="(attachment, index) in company.submission_attachments" :key="index">
            <a v-if="typeof attachment === 'string'" 
              :href="attachment" 
              target="_blank" class="me-2 text-decoration-none text-secondary" :alt="attachment">
                <i class="flaticon-document position-relative top-2 fs-20"></i>
            </a>
        </div>
      </div>
    </template>
    <template v-else>
      No file(s)
    </template>
   
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {companiesEmits} from '@/components/Companies/CompaniesUtils.js'

export default defineComponent({
  name: "CompanyAttachmentsCell",
  props: ['company'],
  emits: companiesEmits,
});
</script>