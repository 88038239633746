<template>
    {{ company.location_country_HQ }}
</template>
<script>
import {companiesEmits} from '@/components/Companies/CompaniesUtils.js'

export default {
  props: ['company'],
  emits: companiesEmits,
};
</script>