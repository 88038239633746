export const resetData = (target, defaults) => {

    Object.keys(defaults).forEach((key) => {
        if (typeof defaults[key] === 'object' && defaults[key] !== null && !Array.isArray(defaults[key])) {
            if (!target[key]) {
                target[key] = {}; // Ensure the target key exists
            }
            resetData(target[key], defaults[key]);
        } else {
            target[key] = defaults[key];
        }
    });

};