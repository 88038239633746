<template>
  <div class="card mb-25 border-0 rounded-0 bg-white profile-intro-card" v-if="userProfile">
    <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
      <div class="user-info d-flex align-items-center">
        <div class="image position-relative">
          <template v-if="!userProfile.profile_image">
            <span class="d-flex justify-content-center align-items-center bg-primary rounded-circle" style="display: block; height: 85px; width: 85px">
              <i class="flaticon-user-1 text-white" style="font-size:40px; line-height: 1;"></i>
            </span>
          </template>
          <template v-else>
            <img :src="userProfile.profile_image" class="rounded-circle" width="85" height="85" alt="user" />
          </template>
        </div>
        <div class="ms-20">
          <h5 class="text-black fw-black mb-1">
            <template v-if="userData.first_name">{{ userData.first_name }} 
              <template v-if="userData.last_name">{{ userData.last_name }}</template>
              </template>
            <template v-else>{{ userData.username }}</template>
          </h5>
        </div>
      </div>
      <div class="content position-relative">
        <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-8">
          Account type: {{ userProfile.is_organization_admin ? 'Admin' : userProfile.role.charAt(0).toUpperCase() + userProfile.role.slice(1).toLowerCase() }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed} from "vue";
import stateStore from "@/stores/stateStore";
import { useUserStore } from '@/stores/userStore';

export default defineComponent({
  name: "ProfileContent",
  setup() {
    const userStore = useUserStore();
    const userProfile = computed(() => userStore.getProfile());
    const userData = computed(() => userStore.getUser());
    return {
      userProfile,
      userData
    };
  },
  
});

</script>