import { API_URL, makeAuthenticatedRequest } from '@/utils/apiUtils';

export const statusService = {
  async updateCompanyStatus(company_id, status) {
    let url = `${API_URL}/company/${company_id}/status/`;

    const payload = {
      status
    }

    return await makeAuthenticatedRequest(url, 'PATCH', payload);
  },
  async updateCompaniesStatus(companyIds, status, reason = false) {
    let url = `${API_URL}/company/statuses/`;

    if (reason && typeof reason !== 'string') {
        throw new Error("Invalid reason: Reason must be a string");
    }

    const payload = {
      status,
      company_ids: companyIds,
      status_reason: reason
    }

    return await makeAuthenticatedRequest(url, 'PATCH', payload);
  },
};