<template>
    <span v-if="company.description" tabindex="0" data-bs-toggle="popover" data-bs-trigger="hover focus"
            :data-bs-content="company.description" class="popover-content">
        {{ company.description.split(' ').slice(0, 7).join(' ') }}...
        <router-link :to="'/company-report/' + company.id" class="text-primary fw-bold text-decoration-none">view full report</router-link>
      </span>
  </template>
  
  <script>
  import {companiesEmits} from '@/components/Companies/CompaniesUtils.js'

  export default {
    props: ['company'],
    emits: companiesEmits,
  };
  </script>