import { ref } from 'vue';
import { company_research_data_defaults, label_defaults, getCompanyDefaults } from '@/components/Reports/Companies/CompanyDefaults';

export const company_research_data = ref(JSON.parse(JSON.stringify(company_research_data_defaults)));
export const company = ref(JSON.parse(JSON.stringify(getCompanyDefaults())));

export const labelClasses = ref(label_defaults);
export const reportVisible = ref(false);
export const loadingBarVisible = ref(true);
export const GLOBAL_TAG = ref('');
export const GLOBAL_DISPLAY_BUFFER_FLAG = ref(false);
export const is_research_complete = ref(false);
export const is_streaming = ref(false);
export const is_streaming_complete = ref(false);
export const had_streaming = ref(false)
export const search_name = ref('');
export const search_website = ref('');
export const buttonDisabled = ref(false);
export const abortController = ref(new AbortController());
export const progress_bar = ref(0);
export const counter = ref(0);
export const showReferences = ref(false);
export const report_exists_and_complete = ref(false);
export const report_timer = ref(0);
export const error_modal = ref(false);
export const error_modal_content = ref('')

export const flag_progress_bar_INFO = ref(false);
export const flag_progress_bar_PROD = ref(false);
export const flag_progress_bar_TARAUD = ref(false);
export const flag_progress_bar_BIPOCBEN = ref(false);
export const flag_progress_bar_PRODCOST = ref(false);
export const flag_progress_bar_NEWS = ref(false);
export const flag_progress_bar_REVIEWS = ref(false);
export const flag_progress_bar_EREVIEWS = ref(false);
export const flag_progress_bar_UREVIEWS = ref(false);
export const flag_progress_bar_LEGAL = ref(false);
export const feedbackReport = ref(null);
export const gap_scores = ref([0, 0, 0, 0, 0, 0, 0])
export const gap_scores_labels = ref([false, false, false, false, false, false, false])