<template>
  <BreadCrumb PageTitle="Inbound" />
  <CompaniesView  />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import CompaniesView from "../../components/Companies/CompaniesView.vue";

export default defineComponent({
  name: "InboundPage",
  components: {
    BreadCrumb,
    CompaniesView,
  },
});
</script>