<template>

    <HeaderPrecisionPicks v-if="isPrecisionPicks" />

    <CompaniesToolbar 
        @view-change="handleViewChange" 
        @filters-applied="handleFiltersApplied"
        @status-update="updateCompaniesStatus"
        :is-precision-pick="isPrecisionPicks" 
    />

    <div v-if="isGridView">
        <CompaniesGridView :companies="companies" :isPrecisionPicks="isPrecisionPicks"
            @update-status="updateCompanyStatus"
            @update:isGridView="isGridView = $event" />
    </div>

    <div v-else>
        <CompaniesListView 
            :companies="companies" 
            :isPrecisionPicks="isPrecisionPicks"
            :handle-company-checkbox="handleCompanyCheckbox"
            :listViewSettings="listViewSettings"
            :listViewSettingsOrder="listViewSettingsOrder"
            @updateCompanyStatus="updateCompanyStatus"
            @update:isGridView="isGridView = $event" />
    </div>

    <ResultsPagination 
        :resultsCount="resultsCount"
        :companiesDisplayed="companiesDisplayed"
        :previousPage="previousPage"
        :nextPage="nextPage" 
        :currentPage="currentPage"
        :pageSize="pageSize"
        @page-changed="handlePageChange"
    />

    <CompaniesSettings :isPrecisionPicks="isPrecisionPicks"  @listview-setting-change="handleListViewSettingChange" @listview-setting-order="handleListViewOrderChange" />

    <b-modal v-model="modalAction" no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    centered title="Passing Reason" hide-footer hide-header dialog-class="modal-sm">
        
        <div class="d-block">

            <h5 class="mb-3">Reason for Passing:</h5>
            
            <div class="btn-group-vertical" role="group" style="width: 100%">

                <input type="radio" class="btn-check" name="btnradio" id="PASSTRAC" autocomplete="off" v-model="statusReason" value="Lack of Traction">
                <label class="btn btn-outline-primary" for="PASSTRAC" @click="statusReason = 'Lack of Traction'">Lack of Traction</label>

                <input type="radio" class="btn-check" name="btnradio" id="PASSEARLY" autocomplete="off" v-model="statusReason" value="Too Soon">
                <label class="btn btn-outline-primary" for="PASSEARLY" @click="statusReason = 'Too Soon'">Too Soon</label>

                <input type="radio" class="btn-check" name="btnradio" id="PASSSCALE" autocomplete="off" v-model="statusReason" value="Not VC Scaleable">
                <label class="btn btn-outline-primary" for="PASSSCALE" @click="statusReason = 'Not VC Scaleable'">Not VC Scaleable</label>

                <input type="radio" class="btn-check" name="btnradio" id="PASSFUNDING" autocomplete="off" v-model="statusReason" value="Not for VC Funding">
                <label class="btn btn-outline-primary" for="PASSFUNDING" @click="statusReason = 'Not for VC Funding'">Not for VC Funding</label>

                <input type="radio" class="btn-check" name="btnradio" id="PASSGCI" autocomplete="off" v-model="statusReason" value="Not Gap Closing">
                <label class="btn btn-outline-primary" for="PASSGCI" @click="statusReason = 'Not Gap Closing'">Not Gap Closing</label>

                <input type="radio" class="btn-check" name="btnradio" id="PASSGEO" autocomplete="off" v-model="statusReason" value="Not within Geography">
                <label class="btn btn-outline-primary" for="PASSGEO" @click="statusReason = 'Not within Geography'">Not within Geography</label>

                <input type="radio" class="btn-check" name="btnradio" id="PASSIND" autocomplete="off" v-model="statusReason" value="Not Industry Aligned">
                <label class="btn btn-outline-primary" for="PASSIND" @click="statusReason = 'Not Industry Aligned'">Not Industry Aligned</label>

                <input type="radio" class="btn-check" name="btnradio" id="PASSSTG" autocomplete="off" v-model="statusReason" value="Not within Stage">
                <label class="btn btn-outline-primary" for="PASSSTG" @click="statusReason = 'Not within Stage'">Not within Stage</label>

    
            </div>

        </div>

        <div class="d-flex justify-content-between">
            <b-button class="mt-3 btn btn-secondary btn-sm" @click="modalAction = false">Cancel</b-button>
            <b-button class="mt-3 btn btn-primary btn-sm" @click="updateCompanyStatus(selectedCompanyId, 'Pass', statusReason); modalAction = false; statusReason = null">Save</b-button>
        </div>

    </b-modal>

</template>

<script lang="ts">
import { defineComponent, onMounted, computed, ref } from "vue";
import { useRoute } from "vue-router";
import CompaniesGridView from './Lists/CompaniesGridView.vue';
import CompaniesListView from './Lists/CompaniesListView.vue';
import HeaderPrecisionPicks from './Headers/HeaderPrecisionPicks.vue';
import CompaniesToolbar from './Toolbar/CompaniesToolbar.vue';
import CompaniesSettings from './Settings/CompaniesSettings.vue';
import { inboundService } from '@/api/inboundService';
import { statusService } from '@/api/statusService';
import ResultsPagination from '../Common/ResultsPagination.vue';
import { setupControlScale } from "@vue-leaflet/vue-leaflet/dist/src/functions/controlScale";
import { Modal } from "bootstrap"; 



type Company = {
    id: number;
    logo: string;
    name: string;
    alignment: string;
    segments: string[];
    market_type: string[];
    status: string;
    status_reason: boolean | string;
    raised: string;
    description: string;
    quantity?: number | string; // Include other properties as needed
};

export default defineComponent({
    name: "CompaniesView",
    components: {
        CompaniesGridView,
        CompaniesToolbar,
        HeaderPrecisionPicks,
        CompaniesListView,
        CompaniesSettings,
        ResultsPagination
    },
    setup() {
        const route = useRoute();

        const companies = ref<Company[]>([]);
        const resultsCount = ref(0);
        const companiesDisplayed = ref(0);
        const nextPage = ref(null);
        const previousPage = ref(null);
        const currentPage = ref(route.query.page ? parseInt(route.query.page as string) : 0);
        const pageSize = ref(25);
        const getParams = ref('');
        const statusReason = ref('');
        const selectedCompanyId = ref<number | null>(null);
        const modalAction = ref(false);
        const listViewSettings = ref({});
        const listViewSettingsOrder = ref({});


        const handleListViewSettingChange = (newSettings) => {
            listViewSettings.value = newSettings;
        };

        const handleListViewOrderChange = (newSettings) => {
            listViewSettingsOrder.value = newSettings;
        };

        const handlePageChange = async (page) => {
            currentPage.value = page;
            await getCompanyList(page);
        };

        const getCompanyList = async (page = currentPage.value) => {
            let params = getParams.value ? getParams.value : false;

            // Clear the companies list before fetching new data
            companies.value = [];

            let response = await inboundService.getInboundCompanies(page, params);

            currentPage.value = response.data.current_page;
            pageSize.value = response.data.page_size;

            resultsCount.value = response.data.total;
            nextPage.value = response.data.links.next;
            previousPage.value = response.data.links.previous;

            // Ensure response.data.results is an array
            if (Array.isArray(response.data.results)) {
                companies.value = response.data.results;
                companiesDisplayed.value = response.data.results.length;
            } else {
                console.error('Expected an array but got:', response.data.results);
            }

            return response;
        };

        const handleFiltersApplied = async (filters, globalFilters, callback) => {
            let getParamsStr = "";

            // MAKE DRY
            if (filters && typeof filters === 'object') {

                for (let filter in filters) {
                    let filtersStr = filters[filter];
                    
                    if (filtersStr) {
                        if (filter != 'STARTDATE' && filter != 'ENDDATE') {
                            filtersStr = filters[filter].join(',');
                            filtersStr = filtersStr.replace('&', '%26')
                            filtersStr = filtersStr.replace('?', '%3f')   
                        }
                        
                        if (filters[filter].length > 0) {
                            if (getParamsStr !== "")
                                getParamsStr += "&";

                            getParamsStr += `${filter}=${filtersStr}`;
                        }
                    }
                }

            }

            if (globalFilters && typeof globalFilters === 'object') {

                for (let filter in globalFilters) {
                    let filtersStr = globalFilters[filter];

                    if (filtersStr) {
                        if (filter != 'STARTDATE' && filter != 'ENDDATE') {
                            filtersStr = globalFilters[filter].join(',');
                            filtersStr = filtersStr.replace('&', '%26')
                            filtersStr = filtersStr.replace('?', '%3f')   
                        }
                        
                        if (globalFilters[filter].length > 0) {
                            if (getParamsStr !== "")
                                getParamsStr += "&";

                            getParamsStr += `${filter}=${filtersStr}`;
                        }
                    }
                }

            }

            getParams.value = getParamsStr;
            currentPage.value = 0;

            await getCompanyList();

            callback();
        };

        onMounted(async () => {
            
            let response;

            if (route.path === '/ai-precision-picks') {
                // companies.value = await import('./companies-outbound.json').then(module => module.default);
            } else {
                try {
                    response = await getCompanyList();

                } catch (err) {
                    console.log(err);
                 }
            }
        });

        return {
            companies,
            companiesDisplayed,
            resultsCount,
            nextPage,
            previousPage,
            handleFiltersApplied,
            getCompanyList,
            handlePageChange,
            currentPage,
            pageSize,
            statusReason,
            selectedCompanyId,
            modalAction,
            listViewSettings,
            handleListViewSettingChange,
            listViewSettingsOrder,
            handleListViewOrderChange
        };
    },
    data() {
        return {
            isGridView: false,
            isCollapsed: true,
            checkedCompanyIds: [] as number[],
        }
    },
    computed: {
        isPrecisionPicks() {
            return this.$route.path === '/ai-precision-picks';
        },
    },
    methods: {
        handleViewChange(isGridView) {
            this.isGridView = isGridView;
        },
        async updateCompanyStatus(id, status, reason = false) {

            if (!id || !status) {
                return
            }

            this.selectedCompanyId = id

            const strToStatusMap = {
                'New': 'NEW',
                'Pass': 'PASD',
                'In Progress': 'INPRG',
                '1st Meeting': '1STMT',
                '2nd Meeting': '2NDMT',
                'Invested': 'INVST',
            };

            const StatusToStrMap = {
                'NEW': 'New',
                'PASD': 'Passed',
                'INPRG': 'In Progress',
                '1STMT': '1st Meeting',
                '2NDMT': '2nd Meeting',
                'INVST': 'Invested',
            };

            const statusValue = strToStatusMap[status]

            if (!statusValue) {
                console.error(`Invalid status: ${status}`);
                return;
            }

            // If Pass show modal
            if (statusValue === 'PASD' && reason === false) {
                this.modalAction = true
                return;
            }

            let response = await statusService.updateCompaniesStatus([id], statusValue, reason);
            
            if (response.status === 200) {
                for (let company of this.companies) {
                    if ([id].includes(company.id)) {
                        if (company.id === id) {
                            company.status_reason = reason;
                            company.status = StatusToStrMap[statusValue];
                        }
                        break;
                    }
                }
            }

            this.statusReason = '';

            const event = new CustomEvent('status-updated');
            window.dispatchEvent(event);

            return;

        },
        async updateCompaniesStatus(status: string, ids: number[] | null = null) {

            let list_ids = this.checkedCompanyIds

            if (ids !== null) {
                if (!Array.isArray(ids) || ids.length === 0) {
                    throw new Error("Invalid or empty IDs array");
                }
                list_ids = ids
            }

            let response = await statusService.updateCompaniesStatus(list_ids, status);

            if (response.status === 200) {
                const statusToStrMap = {
                    'PASD': 'Passed',
                    'INPRG': 'In Progress',
                    '1STMT': '1st Meeting',
                    '2NDMT': '2nd Meeting',
                    'INVST': 'Invested',
                }


                for (let company of this.companies) {
                    if (list_ids.includes(company.id)) {
                        company.status = statusToStrMap[status];
                    }
                }
            }            
        },
        handleCompanyCheckbox(id, checked) {
            if (checked) {
                this.checkedCompanyIds.push(id);
            } else {
                const index = this.checkedCompanyIds.indexOf(id);
                if (index !== -1) {
                    this.checkedCompanyIds.splice(index, 1);
                }
            }
        }
    },
});
</script>