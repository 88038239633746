<template>
<div class="card mb-25 border-0 rounded-0 bg-white welcome-support-desk-box">
  <div
      class="card-body p-15 p-sm-20 p-md-25 p-lg-30 pt-xl-50 pb-xl-50 ps-xl-40 ps-xl-40 letter-spacing"
    >
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="content">
            <h2 class="fw-semibold mb-8">
              Welcome to
              <span class="fw-bold">AI Precision Picks</span>
            </h2>
            <p class="text-black-emphasis fs-md-15 fs-lg-16 mt-4">
                Imaging having a research assistant that searches the internet 24/7 focused soley on finding interesting companies from demo days, pitch competitions, and new product launches; ensuring you do not miss out on a new investment opportunities. Imagine no more with AI Precision Picks. 
            </p>
            <p class="text-black-emphasis fs-md-15 fs-lg-16">
                Based on your criteria our assistant works for you to curate a must-see list of emerging companies by sourcing from platforms like Product Hunt, Techstars, and Y Combinator. 
            </p>
            <div class="row list justify-content-center">
              <div
                class="col-lg-6 col-sm-6"
                v-for="update in updates"
                :key="update.id"
              >
                <div :class="['p-15', update.bgClass]">
                  <span
                    class="d-block mb-6 fw-medium text-black-emphasis fs-13 text-uppercase"
                  >
                    {{ update.title }}
                  </span>
                  <h4 :class="['mb-0 fw-black', update.class]">
                    {{ update.number }}+
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 text-center mt-15 mt-md-0">
          <img
            src="../../../assets/images/welcome/welcome2.png"
            alt="welcome-image"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HeaderPrecisionPicks",
});
</script>