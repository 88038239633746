<template>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="companiesSettings">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title">Settings</h5>
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>

        <div class="offcanvas-body">
            <ul ref="sortableList" class="list-group">
                <li v-for="item in sortableItems" :key="item.key" class="list-group-item text-secondary sortable-item" :data-key="item.key">
                    <div class="d-flex align-items-center">
                        <input 
                            type="checkbox" 
                            :id="item.key" 
                            :value="item.key" 
                            v-model="item.enabled"
                            class="form-check-input me-2"
                            @change="handleItemChange(item)"
                        >
                        <i class="ph ph-arrows-vertical me-2"></i>
                        <label :for="item.key" class="form-check-label">
                            <strong>{{ item.header }}</strong>
                        </label>
                    </div>
                </li>
            </ul>

            <button @click="resetDefault" class="btn btn-secondary btn-sm mt-25">Reset to Defaults</button>
        </div>
    </div>
</template>

<script lang="ts">
import { nextTick, defineComponent, onMounted, ref, watch, computed } from 'vue';
import { companiesState, loadCellSettings, saveCellSettings, getDefaultSettings } from './CompaniesCellState';
import Sortable from 'sortablejs';

export default defineComponent({
    props: {
        isPrecisionPicks: Boolean,
    },
    emits: ["listview-setting-change"],
    setup(props, {emit}) {
        const sortableList = ref<HTMLElement | null>(null);
        const cellSettings = ref(loadCellSettings(props.isPrecisionPicks));

        const sortableItems = computed(() => {
            return cellSettings.value.cells
                .map(cell => ({
                    ...cell,
                    ...companiesState.commonConfig.find(config => config.key === cell.key)
                }))
                .filter(item => item && item.header !== "Company");
        });

        const resetDefault = () => {
            cellSettings.value = getDefaultSettings(props.isPrecisionPicks);
            saveCellSettings(cellSettings.value, props.isPrecisionPicks);
            emit('listview-setting-change', cellSettings.value);
        };

        const initializeSortable = () => {
            if (sortableList.value) {
                new Sortable(sortableList.value, {
                    onEnd: (event) => {
                        if (sortableList.value) {
                            const newOrder = Array.from(sortableList.value.children)
                                .map((childElement) => childElement.getAttribute('data-key'))
                                .filter((key): key is string => key !== null);

                            if (newOrder.length > 0) {
                                const companyCell = cellSettings.value.cells.find(cell => 
                                    companiesState.commonConfig.find(config => config.key === cell.key && config.header === "Company")
                                );
                                cellSettings.value.cells = companyCell 
                                    ? [companyCell, ...newOrder.map(key => ({ key, enabled: cellSettings.value.cells.find(cell => cell.key === key)?.enabled ?? true }))] 
                                    : newOrder.map(key => ({ key, enabled: cellSettings.value.cells.find(cell => cell.key === key)?.enabled ?? true }));
        
                                saveCellSettings(cellSettings.value, props.isPrecisionPicks);
                                emit('listview-setting-change', cellSettings.value);
                            }
                        }
                    }
                });
            }
        };

        const handleItemChange = (item) => {
            const index = cellSettings.value.cells.findIndex(cell => cell.key === item.key);
            if (index !== -1) {
                cellSettings.value.cells[index].enabled = item.enabled;
                saveCellSettings(cellSettings.value, props.isPrecisionPicks);
                emit('listview-setting-change', cellSettings.value);
            }
        };

        onMounted(() => {
            initializeSortable();
        });

        watch(() => props.isPrecisionPicks, () => {
            cellSettings.value = loadCellSettings(props.isPrecisionPicks);
        });

        return {
            sortableList,
            sortableItems,
            resetDefault,
            handleItemChange
        };
    },
});
</script>