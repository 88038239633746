<template>
  <div class="row">
    <div class="col-xxl-8 order-2 order-xl-1">
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <ProfileLinks />
      </div>
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing settings-card"
      >
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <form>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    First Name
                  </label>
                  <input
                    type="text"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. Adam"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Last Name
                  </label>
                  <input
                    type="text"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. Smith"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Phone No
                  </label>
                  <input
                    type="text"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. +001 321 4567"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Select Your Role
                  </label>
                  <select class="form-select shadow-none fw-semibold rounded-0">
                    <option selected>GP</option>
                    <option value="1">LP</option>
                    <option value="2">Analyst</option>
                    <option value="3">Platform Support</option>
                    <option value="4">Owner</option>
                  </select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="d-flex align-items-center justify-content-between">
                  <button
                    class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                    type="submit"
                  >
                    Save Changes
                  </button>
                  <button
                    type="button"
                    class="bg-transparent p-0 border-0 text-danger lh-1 fw-medium"
                  >
                    <i
                      class="flaticon-delete lh-1 me-1 position-relative top-2"
                    ></i>
                    <span class="position-relative">Cancel</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-xxl-4 order-1 order-xl-2">
      <ProfileContent />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import ProfileContent from "./ProfileContent.vue";
import ProfileLinks from "./ProfileLinks.vue";

export default defineComponent({
  name: "AccountSettings",
  components: {
    ProfileContent,
    ProfileLinks,
  },
});
</script>