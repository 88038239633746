<template>
  <template v-if="company.descriptors">
    <span v-for="(descriptor, index) in company.descriptors" :key="descriptor" :class="descriptorBadgeClass(company.in_thesis, company.gap_closing_score)">
      <template v-if="index < 6">{{ descriptor }}</template>
      <template v-else-if="index === 6">...</template>
    </span>
  </template>
</template>

<script>
import {companiesEmits} from '@/components/Companies/CompaniesUtils.js'

export default {
  props: ['company'],
  emits: companiesEmits,
  methods: {
    descriptorBadgeClass(alignment, score) {
      return {
        'badge fs-13 me-1': true,
        'text-outline-success': alignment == true && score >= 7,
        'text-outline-primary': alignment == true && score < 7 && score >= 6.5,
        'text-outline-danger': alignment == false,
        'text-outline-warning': alignment == true && score < 6.5
      };
    }
  }
};
</script>