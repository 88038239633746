<template>
    <div class="card mb-25 border-0 rounded-0 bg-white">
      <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
        <div
          class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing"
        >
          <h5 class="card-title fw-bold mb-0">Notifications</h5>
          <div
            class="card-select mt-10 mt-sm-0 d-inline-block d-sm-flex align-items-center ps-10 pe-10 pt-5 pb-5"
          >
            <span class="fw-medium text-muted me-8">Last</span>
            <select
              class="form-select shadow-none text-black border-0 ps-0 pt-0 pb-0 pe-20 fs-14 fw-medium"
            >
              <option value="1" class="fw-medium" selected>1 Week</option>
              <option value="2" class="fw-medium">2 Week</option>
              <option value="3" class="fw-medium">3 Week</option>
            </select>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table text-nowrap align-middle mb-0">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >
                  Date
                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="notification in notifications" :key="notification.id">
                <td class="shadow-none lh-1 fw-medium text-black-emphasis ps-0">
                  <a
                    href="#"
                    class="text-decoration-none text-black"
                  >
                   {{ notification.message }}
                  </a>
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">{{ notification.date }}</td>
                <td
                  class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
                >
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i
                            class="flaticon-view lh-1 me-8 position-relative top-1"
                          ></i>
                          Mark as Viewed</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i
                            class="flaticon-delete lh-1 me-8 position-relative top-1"
                          ></i>
                          Delete</a
                        >
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
<script lang="ts">
import { defineComponent } from "vue";
import notificationsData from "./notifications.json";

export default defineComponent({
  name: "NotificationsContent",
  data() {
    return {
        notifications: notificationsData,
    };
  },
});
</script>