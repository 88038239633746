  <template>
    <h1 class="title mt-10">This feature is planned and under construction</h1>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "UnderConstructionPage",
  });
  </script>