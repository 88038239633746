import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/userStore';

export const API_URL = process.env.VUE_APP_BACKEND_BASE_URL + "/api";
export const GCI_SSE_ULR = process.env.VUE_APP_AWS_LAMBDA_URL;

export const handleRequest = async (request) => {
  try {
    return await request();
  } catch (error) {
    console.error('API request error:', error);
    throw new Error('Failed to complete API request');
  }
};

export const makeAuthenticatedRequest = async (endpoint, method = "GET", payload = {}) => {

  const router = useRouter();
  const userStore = useUserStore();

  let jwt = '';

  try {
    jwt = await userStore.getValidAccessToken();
  } catch (error) {
    if (error.message === "No valid tokens available.") {
      if (router) return router.push({ name: 'LoginPage' });
    }

    throw error;
  }

  const options = {
    method,
    headers: { Authorization: `Bearer ${jwt}` },
  };

  if (method === 'POST' || method === 'PATCH' || method === 'PUT') {
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(payload);
  }

  try {
    const response = await fetch(endpoint, options);
    if (!response.ok) {
      console.log(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return { data: data, status: response.status };
  } catch (error) {
    console.error('Fetch error:', error);
    throw new Error('Failed to fetch data from the server');
  }

}