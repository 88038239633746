<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing lead-info-box">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <div class="profile-info d-sm-flex align-items-center" v-if="company_research_data && company_research_data.INFO">
        <template v-if="!company_research_data || !company_research_data.INFO || !company_research_data.INFO.logo">
          <!-- <span class="d-flex justify-content-center align-items-center bg-primary rounded me-2"
            style="display: block; height: 85px; width: 85px">
            <i class="flaticon-search text-white" style="font-size:50px; line-height: 1;"></i>
          </span> -->
        </template>
        <template v-else>
          <!-- <span class="d-flex justify-content-center align-items-center bg-primary rounded me-2"
            style="display: block; height: 85px; width: 85px">
            <i class="flaticon-search text-white" style="font-size:50px; line-height: 1;"></i>
          </span> -->
          <!-- <img :src="company_research_data.INFO.logo" class="rounded border p-2" alt="user" /> -->
        </template>

        <div class="title mt-12 mt-sm-0">
          <h5 class="text-black fw-medium mb-8" v-if="company.name">{{ company.name }}</h5>
          <template v-else>
            <h5 class="placeholder-glow mb-8">
              <span class="placeholder col-12"></span>
            </h5>    
          </template>
          <span
              v-if="company.gap_closing_category && company.gap_closing_category.AVG.title"  @click="$emit('goToTab', 'gci', 'averaged_gap_analysis')"
              :class="{
            'badge rounded-pill fs-13 me-2 cursor-pointer': true,
            'text-bg-secondary': company.gap_closing_category.AVG.title.toLowerCase().includes('neutral') == true,
            'text-bg-success': company.gap_closing_category.AVG.title.toLowerCase().includes('closing') == true,
            'text-bg-warning': company.gap_closing_category.AVG.title.toLowerCase().includes('widening') == true,
          }">
            {{ company.gap_closing_category.AVG.title }}
          </span>
          <template v-else>
            <span class="placeholder-glow"><span class="placeholder col-10 placeholder-lg"></span></span>
          </template>
          <div class="d-flex mt-2 fs-md-20 text-muted" v-if="company_research_data && company_research_data.INFO">
            <a v-if="company_research_data.INFO.linkedin_url" :href="company_research_data.INFO.linkedin_url"
              target="_blank" class="mr-2 text-decoration-none">
              <i class="icon ph-bold ph-facebook-logo"></i>
            </a>
            <a v-if="company_research_data.INFO.twitter_url" :href="company_research_data.INFO.twitter_url"
              target="_blank" class="mr-2 text-decoration-none">
              <i class="icon ph-fill ph-twitter-logo"></i>
            </a>
            <a v-if="company_research_data.INFO.facebook_url" :href="company_research_data.INFO.facebook_url"
              target="_blank" class="mr-2 text-decoration-none">
              <i class="icon ph-bold ph-linkedin-logo"></i>
            </a>
          </div>
          <div v-else class="mt-2">
            <span class="placeholder-glow"><span class="placeholder col-6"></span></span>
          </div>
        </div>
      </div>
      <div v-else>
        <h5 class="placeholder-glow"><span class="placeholder col-11"></span></h5>
        <h5 class="placeholder-glow"><span class="placeholder col-12"></span></h5>
        <h5 class="placeholder-glow"><span class="placeholder col-11"></span></h5>
      </div>
      <template v-if="canAccessFeature('companyActions')"> 
        <div class="border-top mt-5 mb-5 mt-sm-10 mb-sm-10 mt-md-15 mb-md-5 mt-lg-30 mb-lg-30"></div>
        <div v-if="company_research_data && company_research_data.INFO">
          <h6 class="fw-medium text-black-emphasis mb-0">Quick Actions</h6>
          <div class="d-flex align-items-center mt-3 mb-3">
            <button class="btn btn-primary me-2"
              style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"> <i
                class="flaticon flaticon-heart me-2"></i>Favorite</button>
            <button class="btn btn-primary me-2"
              style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"> <i
                class="flaticon flaticon-plus me-2"></i>Add to List</button>
            <button class="btn btn-danger"
              style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"> <i
                class="flaticon flaticon-close me-2"></i>Pass</button>
          </div>
        </div>
        <div v-else>
          <h6 class="placeholder-glow"><span class="placeholder col-10"></span></h6>
          <h6 class="placeholder-glow"><span class="placeholder col-8"></span></h6>
        </div>
      </template>
      <div class="border-top mt-15 mb-15 mt-sm-20 mb-sm-20 mt-md-25 mb-md-25 mt-lg-30 mb-lg-30"></div>
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="fw-medium text-black-emphasis mb-0">Company Information</h5>
        </div>
        <ul class="info mt-25 ps-0 mb-0 list-unstyled" v-if="company_research_data && company_research_data.INFO">
          <li class="position-relative"
            v-if="company.reputation_risks && company.reputation_risks.flags && company.reputation_risks.flags?.length > 0">
            <div class="icon text-warning rounded-circle text-center">
              <i class="flaticon-info"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold"> Reputation Risks </span>
            <template v-for="(flag, index) in company.reputation_risks.flags" :key="index">
              <span class="text-bg-warning badge me-1 mb-1 cursor-pointer" 
                    v-if="flagMap[flag]" 
                    @click="$emit('goToTab', 'company', flagMap[flag].report)">
                    {{ flagMap[flag].label }}
                </span>
            </template> 
          </li>
          <li class="position-relative"
            v-if="company_research_data && company_research_data.INFO && company_research_data.INFO.stage">
            <div class="icon text-warning rounded-circle text-center">
              <i class="flaticon-industry"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Stage</span>
            <span class="d-inline-block fs-md-15 fs-lg-16 text-muted">
              {{ company_research_data.INFO.stage }}
              <template v-if="company_research_data.INFO.is_currently_raising">
                and is <span class="text-dark">Currently Raising</span>
              </template>
            </span>
          </li>
          <li class="position-relative"
            v-if="company_research_data.INFO && company_research_data.INFO.total_funding_raised != 0">
            <div class="icon text-success rounded-circle text-center">
              <i class="flaticon-money"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">
              Total Raised (per crunchbase)
            </span>
            <span class="d-inline-block fs-md-15 fs-lg-16 text-muted">
              {{ '$' + company_research_data.INFO.total_funding_raised.replace(/\.\d+/,
          '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
            </span>
          </li>
          <li class="position-relative"
            v-if="company.raw_data && company.raw_data[0] && company.raw_data[0].raw_json && company.raw_data[0].raw_json.tags">
            <div class="icon text-warning rounded-circle text-center">
              <i class="flaticon-status"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold"> Segments </span>
            <template v-for="(value, key) in company.raw_data[0].raw_json.tags" :key="key">
              <span class="badge text-outline-info me-1 mb-1" v-if="value">{{ key }}</span>
            </template>
          </li>
          <li class="position-relative"
            v-if="company.raw_data && company.raw_data[0] && company.raw_data[0].raw_json && company.raw_data[0].raw_json.sales_strategies">
            <div class="icon text-primary rounded-circle text-center">
              <i class="flaticon-industry"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Business Model </span>
            {{ company.raw_data[0].raw_json.sales_strategies.business_type }} ({{
          company.raw_data[0].raw_json.sales_strategies.purchasers }})
          </li>
          <li class="position-relative"
            v-if="company.raw_data && company.raw_data[0] && company.raw_data[0].raw_json && company.raw_data[0].raw_json.sales_strategies.target_audience">
            <div class="icon text-dark rounded-circle text-center">
              <i class="flaticon-shopping-cart-2"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Target Audience </span>
            {{ company.raw_data[0].raw_json.sales_strategies.target_audience }}
          </li>
          <li class="position-relative" v-if="company_research_data.INFO.founding_year">
            <div class="icon text-secondary rounded-circle text-center">
              <i class="flaticon-calendar"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Year Started</span>
            {{ company_research_data.INFO.founding_year }}
          </li>
          <li class="position-relative" v-if="company_research_data.INFO.hq">
            <div class="icon text-danger rounded-circle text-center">
              <i class="flaticon-maps-and-flags"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Head Quartered</span>
            <template v-if="company_research_data.INFO.hq.address">
            {{ company_research_data.INFO.hq.address }}, 
            </template>
            <template v-if="company_research_data.INFO.hq.city"> {{
          company_research_data.INFO.hq.city }}</template><template v-if="company_research_data.INFO.hq.state">, {{
          company_research_data.INFO.hq.state }}</template><template v-if="company_research_data.INFO.hq.country">,
              {{ company_research_data.INFO.hq.country }}</template>
          </li>
          <li class="position-relative" v-if="company_research_data.INFO.contact_email">
            <div class="icon text-success rounded-circle text-center">
              <i class="flaticon-mail-inbox-app"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Email</span>
            <a :href="'mailto:' + company_research_data.INFO.contact_email"
              class="d-inline-block fs-md-15 fs-lg-16 text-primary text-decoration-none">
              {{ company_research_data.INFO.contact_email }}
            </a>
          </li>
          <li class="position-relative" v-if="company_research_data.INFO.contact_phone_number">
            <div class="icon text-info rounded-circle text-center">
              <i class="flaticon-telephone-call"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Phone No</span>
            <a :href="'tel:' + company_research_data.INFO.contact_phone_number"
              class="d-inline-block fs-md-15 fs-lg-16 text-primary text-decoration-none">
              {{ company_research_data.INFO.contact_phone_number }}
            </a>
          </li>
          <li class="position-relative" v-if="company.website">
            <div class="icon text-primary rounded-circle text-center">
              <i class="flaticon-web"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Website</span>
            <a :href="company.website" target="_blank"
              class="d-inline-block fs-md-15 fs-lg-16 text-primary text-decoration-none">
              {{ company.website }}
            </a>
          </li>
          <li class="position-relative" v-if="company_research_data.INFO.updated_at">
            <div class="icon text-info rounded-circle text-center">
              <i class="flaticon-date-1"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">
              Report Updated
            </span>
            <span class="d-inline-block fs-md-15 fs-lg-16 text-muted">
              {{ formatTimeAgo( company_research_data.INFO.updated_at) }}
            </span>
          </li>
        </ul>
        <div v-else>
          <ul class="mt-25 ps-0 mb-0 list-unstyled">
            <li class="mb-25"> 
              <h3 class="placeholder-glow"><span class="placeholder col-6"></span></h3>
              <h5 class="placeholder-glow"><span class="placeholder col-10"></span></h5>
              <h5 class="placeholder-glow"><span class="placeholder col-8"></span></h5>
            </li>
            <li class="mb-25"> 
              <h3 class="placeholder-glow"><span class="placeholder col-8"></span></h3>
              <h5 class="placeholder-glow"><span class="placeholder col-11"></span></h5>
              <h5 class="placeholder-glow"><span class="placeholder col-5"></span></h5>
            </li>
            <li class="mb-25"> 
              <h3 class="placeholder-glow"><span class="placeholder col-6"></span></h3>
              <h5 class="placeholder-glow"><span class="placeholder col-9"></span></h5>
              <h5 class="placeholder-glow"><span class="placeholder col-10"></span></h5>
            </li>
            <li class="mb-25"> 
              <h3 class="placeholder-glow"><span class="placeholder col-7"></span></h3>
              <h5 class="placeholder-glow"><span class="placeholder col-12"></span></h5>
              <h5 class="placeholder-glow"><span class="placeholder col-5"></span></h5>
            </li>
            <li class="mb-25"> 
              <h3 class="placeholder-glow"><span class="placeholder col-10"></span></h3>
              <h5 class="placeholder-glow"><span class="placeholder col-7"></span></h5>
              <h5 class="placeholder-glow"><span class="placeholder col-9"></span></h5>
            </li>
            <li class="mb-25"> 
              <h3 class="placeholder-glow"><span class="placeholder col-7"></span></h3>
              <h5 class="placeholder-glow"><span class="placeholder col-10"></span></h5>
              <h5 class="placeholder-glow"><span class="placeholder col-9"></span></h5>
            </li>
          </ul>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { formatDistanceToNow } from 'date-fns';
import { canAccessFeature } from "@/utils/featuresUtils"
import { flagMap } from  '@/components/Reports/Companies/CompanyReportUtils';

export default defineComponent({
  name: 'CompanyInformation',
  emits: ['goToTab'],
  props: {
    company: {
      type: Object,
      required: true,
    },
    company_research_data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    flagMap() {
      return flagMap;
    }
  },
  setup() {
    const formatTimeAgo = (dateString) => {
      return formatDistanceToNow(new Date(dateString), { addSuffix: true });
    };
    return {
      formatTimeAgo,
      canAccessFeature
    };
  }
});
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>