<template>
  <div class="row">
    <div class="col-xxl-8 order-2 order-xl-1">
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <ProfileLinks />
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div
          class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
          <div class="d-sm-flex align-items-center">

          </div>
          <div class="d-flex align-items-center">
            <a class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
              href="#" data-bs-toggle="modal" data-bs-target="#createNewUserModal">
              Add New User
              <i class="flaticon-plus position-relative ms-5 fs-12"></i>
            </a>
          </div>
        </div>
        <div class="card-body p-15 p-sm-20 p-md-25">
          <div class="table-responsive">
            <table class="table text-nowrap align-middle mb-0">
              <thead>
                <tr>
                  <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                    USER NAME
                  </th>
                  <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                    EMAIL
                  </th>
                  <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                    ROLE
                  </th>
                  <th scope="col"
                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                        <img src="../../assets/images/user/user3.jpg" class="rounded-circle me-8" width="24" height="24"
                          alt="user" />
                        J. Ronan
                      </div>
                    </div>
                  </th>
                  <td class="shadow-none lh-1 fw-medium text-primary">
                    ronanjohnny@gmail.com
                  </td>
                  <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                    Admin
                  </td>
                  <td class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0">
                    <div class="dropdown">
                      <button class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                        type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="flaticon-dots"></i>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);">
                            <i class="flaticon-pen lh-1 me-8 position-relative top-1"></i>
                            Edit
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);">
                            <i class="flaticon-delete lh-1 me-8 position-relative top-1"></i>
                            Delete
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                        <img src="../../assets/images/user/user12.jpg" class="rounded-circle me-8" width="24"
                          height="24" alt="user" />
                        J. Pinkman
                      </div>
                    </div>
                  </th>
                  <td class="shadow-none lh-1 fw-medium text-primary">
                    pinky321@gmail.com
                  </td>
                  <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                    Staff
                  </td>
                  <td class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0">
                    <div class="dropdown">
                      <button class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                        type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="flaticon-dots"></i>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);">
                            <i class="flaticon-pen lh-1 me-8 position-relative top-1"></i>
                            Edit
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);">
                            <i class="flaticon-delete lh-1 me-8 position-relative top-1"></i>
                            Delete
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <CreateNewUserModal />
    </div>
    <div class="col-xxl-4 order-1 order-xl-2">
      <ProfileContent />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ProfileContent from "./ProfileContent.vue";
import ProfileLinks from "./ProfileLinks.vue";
import CreateNewUserModal from "./CreateNewUserModal.vue";


export default defineComponent({
  name: "PrivacyPolicy",
  components: {
    ProfileContent,
    ProfileLinks,
    CreateNewUserModal
  },
});
</script>