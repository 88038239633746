<template>
  <div class="row">
    <div class="col-xxl-8 order-2 order-xl-1">
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <ProfileLinks />
      </div>
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing settings-card"
      >
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          
          <h5 class="text-black fw-bold mb-12">Delete This Account</h5>

          <div
            class="d-flex align-items-center justify-content-between mt-10 mt-sm-15 mt-md-25 mt-lg-30"
          >
            <a
            class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-danger fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#deleteAccountModal"
          >
              Close & Delete This Account
          </a>
          </div>
        </div>
      </div>
      <DeleteAccountModal />
    </div>
    <div class="col-xxl-4 order-1 order-xl-2">
      <ProfileContent />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ProfileContent from "./ProfileContent.vue";
import ProfileLinks from "./ProfileLinks.vue";
import DeleteAccountModal from "./DeleteAccountModal.vue";


export default defineComponent({
  name: "DeleteAccount",
  components: {
    ProfileContent,
    ProfileLinks,
    DeleteAccountModal
  },
});
</script>