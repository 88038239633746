<template>
  <CompanyReportProgress v-if="company && progress_bar" 
  :is_streaming="is_streaming" 
  :is_streaming_complete="is_streaming_complete" 
  :progress_bar="progress_bar"  
  :company="company" 
  :is_research_complete="is_research_complete" 
  :reportVisible="reportVisible"
  :company_research_data="company_research_data"
  />
  <div class="row">
    <div class="col-lg-5 col-xl-4">
      <CompanyInformation v-if="company" :company="company" :company_research_data="company_research_data" @goToTab="handleGoToTab" />
    </div>
    <div class="col-lg-7 col-xl-8">
      <div class="leads-details-tabs">
        <ul class="nav nav-tabs border-0 mb-25" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link fs-md-15 fs-lg-16 fw-medium border-0 rounded-0 d-block text-center w-100 active position-relative"
              id="gci-tab" data-bs-toggle="tab" data-bs-target="#gci-tab-pane" type="button" role="tab"
              aria-controls="gci-tab-pane" aria-selected="true" :disabled="!company_research_data">
              Gap Closing Report
              <template v-if="had_streaming && !is_research_complete && !is_streaming_complete">
                <span class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-warning">
                 Researching      
                </span>
              </template>
              <template v-if="had_streaming && is_research_complete && !is_streaming_complete">
                <span class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-info">
                 <template v-if="company.supplemental_data.investment_questions">4 out of 4</template>
                 <template v-else-if="company.gap_closing_category.AVG.analysis">3 out of 4</template>
                 <template v-else-if="company.gap_closing_category.POT.analysis">2 out of 4</template>
                 <template v-else-if="company.gap_closing_category.CUR.analysis">1 out of 4</template>
                 <template v-else>In Progress...</template>                 
                </span>
              </template>
              <template v-else-if="had_streaming && is_research_complete && is_streaming_complete">
                <span class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-success">
                 Done   
                </span>
              </template>     
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link fs-md-15 fs-lg-16 fw-medium border-0 rounded-0 d-block text-center w-100 position-relative"
              id="team-tab" data-bs-toggle="tab" data-bs-target="#team-tab-pane" type="button" role="tab"
              aria-controls="team-tab-pane" aria-selected="false"
              :disabled="!company_research_data || !company_research_data.INFO || !company_research_data.INFO.founders || company_research_data.INFO.founders.length <= 0">
              Team Research
              <template v-if="had_streaming && !is_research_complete">
                <span class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-warning">
                 Researching..      
                </span>
              </template>
              <template v-else-if="had_streaming && is_research_complete && (!company_research_data || !company_research_data.INFO || !company_research_data.INFO.founders || company_research_data.INFO.founders.length <= 0)">
                <span class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger">
                 No Data Found  
                </span>
              </template>  
              <template v-else-if="!had_streaming && report_exists_and_complete && is_research_complete && (!company_research_data || !company_research_data.INFO || !company_research_data.INFO.founders || company_research_data.INFO.founders.length <= 0)">
                <span class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger">
                 No Data Found  
                </span>
              </template>  
              <template v-else-if="had_streaming && is_research_complete">
                <span class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-success">
                 Done   
                </span>
              </template>      
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link fs-md-15 fs-lg-16 fw-medium border-0 rounded-0 d-block text-center w-100 position-relative"
              id="company-tab" data-bs-toggle="tab" data-bs-target="#company-tab-pane" type="button" role="tab"
              aria-controls="company-tab-pane" aria-selected="false" :disabled="!company_research_data">
              Company Research
              <template v-if="had_streaming && is_research_complete && company_research_data">
                <span class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-success">
                 Done   
                </span>
              </template>
              <template v-else-if="had_streaming && !is_research_complete && completed_research_count && company_research_display_order">
                <span class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-info">
                  {{ completed_research_count }} out of {{ company_research_display_order.length }}      
                </span>
              </template>
              <template v-else-if="had_streaming && !is_research_complete && company_research_display_order">
                <span class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-info">
                  0 out of {{ company_research_display_order.length }}      
                </span>
              </template>
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link fs-md-15 fs-lg-16 fw-medium border-0 rounded-0 d-block text-center w-100 position-relative"
              id="competition-tab" data-bs-toggle="tab" data-bs-target="#competition-tab-pane" type="button" role="tab"
              aria-controls="competition-tab-pane" aria-selected="false">
              Competition
              <span class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-secondary">
                Under Development   
              </span>
            </button>
          </li>
        </ul>
        <div class="tab-content" id="tabContent">
          <div class="tab-pane fade show active" id="gci-tab-pane" role="tabpanel" tabindex="0">
            <div :class="['card', 'mb-25', 'rounded-0', 'bg-white', 'letter-spacing', GLOBAL_TAG === 'averaged_gap_analysis' ? 'border border-1 border-info' : 'border-0']" id="averaged_gap_analysis">
              <div
                class="card-body pt-0 pb-0 pb-sm-2 pb-md-25 pb-lg-2 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
                <div class="row d-flex">
                  <div class="col-md-6">
                    <h5 class="card-title fw-bold mb-0 mt-30" v-if="company.name">Kapor's Gap-Index for {{ company.name
                      }}</h5>
                    <template v-else>
                      <h4 class="placeholder-glow card-title fw-bold mb-0 mt-30">
                        <span class="placeholder col-10"></span>
                      </h4>
                    </template>
                    <div class="d-flex flex-wrap align-items-center mb-25 mt-20" v-if="company">
                      <span
                        v-if="company.gap_closing_category && company.gap_closing_category.AVG.title"
                        :class="{
    'badge rounded-pill fs-13 me-2 mt-1': true,
    'text-bg-secondary': company.gap_closing_category.AVG.title.toLowerCase().includes('neutral') == true,
    'text-bg-success': company.gap_closing_category.AVG.title.toLowerCase().includes('closing') == true,
    'text-bg-warning': company.gap_closing_category.AVG.title.toLowerCase().includes('widening') == true,
  }">
                        {{ company.gap_closing_category.AVG.title }}
                      </span>

                      <template v-else>
                        <h5 class="placeholder-glow"><span class="placeholder col-8"></span></h5>
                      </template>
                      <span v-if="company.references_count > 0 && company.references_count <= 5"
                        class="badge text-bg-warning rounded-pill fs-13 me-2 mt-1 cursor-pointer"  @click="handleGoToTab('gci', 'references')">Citation Score: Low</span>
                      <span v-if="company.references_count >= 6 && company.references_count <= 14"
                        class="badge text-bg-secondary rounded-pill fs-13 me-2 mt-1 cursor-pointer" @click="handleGoToTab('gci', 'references')">Citation Score: Moderate</span>
                      <span v-if="company.references_count >= 15"
                        class="badge text-bg-primary rounded-pill fs-13 me-2 mt-1 cursor-pointer" @click="handleGoToTab('gci', 'references')">Citation Score:
                        High</span>
                    </div>
                    <template v-else>
                      <h5 class="placeholder-glow"><span class="placeholder col-12"></span></h5>
                    </template>
                    <p id="averaged_gap_analysis" v-if="company.gap_closing_category.AVG.analysis"
                      v-html="company.gap_closing_category.AVG.analysis"></p>
                    <template v-else>
                      <h5 class="placeholder-glow"><span class="placeholder col-12"></span></h5>
                      <h5 class="placeholder-glow"><span class="placeholder col-11"></span></h5>
                      <h5 class="placeholder-glow"><span class="placeholder col-12"></span></h5>
                      <h5 class="placeholder-glow"><span class="placeholder col-9"></span></h5>
                      <h5 class="placeholder-glow"><span class="placeholder col-11"></span></h5>
                    </template>
                  </div>
                  <div class="col-md-6 mt-25">
                    <div class="chart" v-if="company.name && !is_streaming">
                      <apexchart type="bar" :options="companyChart" :series="gap_scores" height="325px">
                      </apexchart>
                    </div>
                    <div v-else>
                      <h1 class="placeholder-glow mt-30"><span class="placeholder col-12"></span></h1>
                      <h1 class="placeholder-glow"><span class="placeholder col-12"></span></h1>
                      <h5 class="placeholder-glow"><span class="placeholder col-9"></span></h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CompanyReputationCard 
              :flags="company.reputation_risks.flags" 
              :content="company.reputation_risks.analysis" 
              :title="'Reputation Concerns'"
              :content_id="'reputation_risks'" 
              :global_tag="GLOBAL_TAG" 
              v-if="company"
              @goToTab="handleGoToTab"
            />
            <CompanyTextCard :content="company.gap_closing_category.CUR.analysis" :title="'Current Operations'"
              :content_id="'current_gap_analysis'" :global_tag="GLOBAL_TAG" v-if="company.gap_closing_category.CUR.analysis" />
            <template v-else>
              <CompanyPlaceholderCard />
            </template>
            <CompanyTextCard :content="company.gap_closing_category.POT.analysis" :title="'Potential Impact'"
              :content_id="'potential_gap_analysis'" :global_tag="GLOBAL_TAG" v-if="company.gap_closing_category.POT.analysis" />
            <template v-else>
              <CompanyPlaceholderCard />
            </template>
            <CompanyTextCard :content="company.supplemental_data.investment_questions" :title="'Investment Questions'"
              :content_id="'investment_questions'" :global_tag="GLOBAL_TAG" v-if="company.supplemental_data.investment_questions" />
            <template v-else>
              <CompanyPlaceholderCard />
            </template>
            <CompanyTextCard :content="company.references" :title="'Data Used for Report'" :content_id="'references'"
              v-if="company.references" />
            <template v-else>
              <CompanyPlaceholderCard />
            </template>
          </div>
          <div class="tab-pane fade" id="team-tab-pane" role="tabpanel" tabindex="0"
            v-if="company_research_data && company_research_data.INFO && company_research_data.INFO.founders && company_research_data.INFO.founders.length > 0">
            <CompanyFounderCard v-for="(founder, index) in company_research_data.INFO.founders" :key="index"
              :founder="founder" />
          </div>
          <template v-if="company_research_data">
            <div class="tab-pane fade" id="company-tab-pane" role="tabpanel" tabindex="0"
              v-if="company_research_data.INFO !== null || company_research_data.TARAUD !== null || company_research_data.BIPOCBEN !== null || company_research_data.PRODCOST !== null || company_research_data.PROD !== null || company_research_data.NEWS !== null || company_research_data.REVIEWS !== null || company_research_data.EREVIEWS !== null || company_research_data.UREVIEWS !== null || company_research_data.LEGAL !== null">
              <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing" v-if="company_research_data.INFO && company.raw_data && company.raw_data[0] && company.raw_data[0].raw_json">
                <div
                  class="card-head bg-white d-md-flex align-items-center justify-content-between pt-25 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
                  <h5 class="card-title fw-bold mb-0">Overview</h5>
                </div>
                <div
                  class="card-body pt-0 pb-0 pb-sm-20 pb-md-25 pb-lg-30 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
                  <p v-if="company.raw_data && company.raw_data[0] && company.raw_data[0].raw_json && company.raw_data[0].raw_json.mission">
                    <strong>Mission:</strong> {{ company.raw_data[0].raw_json.mission.mission }}
                  </p>
                  <p v-if="company.raw_data && company.raw_data[0] && company.raw_data[0].raw_json && company.raw_data[0].raw_json.sales_strategies.business_type">
                    <strong>Business Model: </strong> {{ company.raw_data[0].raw_json.sales_strategies.business_type }}
                  </p>
                  <p v-if="company.raw_data && company.raw_data[0] && company.raw_data[0].raw_json && company.raw_data[0].raw_json.sales_strategies.purchasers">
                    <strong>Target Audience:</strong> {{ company.raw_data[0].raw_json.sales_strategies.purchasers }} -
                    {{ company.raw_data[0].raw_json.sales_strategies.target_audience }}
                  </p>
                  <p v-if="company.raw_data && company.raw_data[0] && company.raw_data[0].raw_json && company.raw_data[0].raw_json.product_pricing.product_costs">
                    <strong>Pricing:</strong> {{ company.raw_data[0].raw_json.product_pricing.product_costs }}
                  </p>
                  <p
                    v-if="company.raw_data && company.raw_data[0] && company.raw_data[0].raw_json.product_pricing.free_tier !== null && company.raw_data[0].raw_json.product_pricing.free_tier !== 'null'">
                    <strong>Free Tier/Trial:</strong> {{ company.raw_data[0].raw_json.product_pricing.free_tier }}
                  </p>
                </div>
              </div>
              <template v-else>
                  <template v-if="!company_research_data">
                    <CompanyPlaceholderCard />
                  </template>
              </template>
              <template v-for="key in company_research_display_order" :key="key">
                <CompanyTextCard :content="company_research_data[key]" :title="research_data_title_map[key]"
                  :content_id="'report-' + key" v-if="company_research_data[key] !== null || company_research_data[key] !== ''" />
                <template v-else>
                  <CompanyPlaceholderCard />
                </template>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="tab-pane fade" id="company-tab-pane" role="tabpanel" tabindex="0">
              <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
                <div class="card-head bg-white d-md-flex align-items-center justify-content-between pt-25 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
                  <h5 class="card-title fw-bold mb-0">No Data Found</h5>
                </div>
              </div>
            </div>
          </template>
          <div class="tab-pane fade" id="competition-tab-pane" role="tabpanel" tabindex="0">
            <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
              <div
                class="card-head bg-white d-md-flex align-items-center justify-content-between pt-25 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
                <h5 class="card-title fw-bold mb-3">This feature is in development</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <b-modal v-model="error_modal" no-close-on-esc no-close-on-backdrop hide-header-close centered title="Something seems off..." hide-header hide-footer>

    <div class="d-block">
      <p><strong>{{error_modal_content}}</strong></p>
      <p>I have logged this issue so a team member can look at this issue!</p>
    </div>

    <b-button class="mt-3" block @click="$router.push({ name: 'SearchPage' })">Go back to search page</b-button>

  </b-modal>

</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onBeforeUnmount, watch, nextTick} from "vue";
import { useRoute, useRouter } from 'vue-router';
import { marked } from 'marked';
import { reportCompanyService } from "@/api/reportCompanyService"
import { userService } from "@/api/userService"
import { resetData } from "@/utils/dataUtils"
import { company_research_data_defaults, research_data_title_map, getCompanyDefaults, label_defaults, company_research_display_order } from './CompanyDefaults.js';
import { setGapTitle, setGapCSS, updateCompanyGapGraphScores } from './CompanyReportUtils.js';
import * as CompanyReportStates from "./CompanyReportStates.js";
import { GCI_SSE_ULR } from '@/utils/apiUtils';
import type { RequestInit } from 'node-fetch';
import { Modal } from "bootstrap"; 
import CompanyInformation from "./CompanyInformation.vue";
import CompanyReportProgress from "./CompanyReportProgress.vue";
import CompanyPlaceholderCard from "./Cards/CompanyPlaceholderCard.vue";
import CompanyTextCard from "./Cards/CompanyTextCard.vue";
import CompanyReputationCard from "./Cards/CompanyReputationCard.vue";
import CompanyFounderCard from "./Cards/CompanyFounderCard.vue";
import { createSidetab } from '@typeform/embed'
import { useUserStore } from '@/stores/userStore';

interface Founder {
  linkedin_url: any;
  // Add other properties as needed
}


export default defineComponent({
  name: "CompanyDetails",
  components: {
    CompanyInformation,
    CompanyReportProgress,
    CompanyPlaceholderCard,
    CompanyTextCard,
    CompanyReputationCard,
    CompanyFounderCard
  },
  emits: ["goToTab"],
  setup() {

  
    class TagProcessor {

      currentTag: string;
      contentBuffer: string;
      isTagOpen: boolean;
      output: any[];

      constructor() {
        this.currentTag = '';
        this.contentBuffer = '';
        this.isTagOpen = false;
        this.output = [];
      }

      checkScore(chunk) {

        let classList = '';

        if (chunk.trim() !== '') {

          const score = parseFloat(chunk);

          if (score < 4) {

            classList = 'is-warning';

          } else if (score >= 7) {

            classList = 'is-success';

          }

        }

        switch (GLOBAL_TAG.value) {

          case 'current_gap_score':
            labelClasses.value.CUR_gap_label = classList;
            break;
          case 'potential_gap_score':
            labelClasses.value.POT_gap_label = classList;
            break;
          case 'averaged_gap_score':
            labelClasses.value.AVG_gap_label = classList;
            break;

        }

      }

      routeTag(tag) {

        switch (tag) {
          case 'METADATA':
            GLOBAL_TAG.value = 'meta_data';
            break;
          case 'ERROR OPENAI REFRESH':
            error_modal.value = true
            error_modal_content.value = "There was an error processing this company."
            clearInterval(longPullInterval);
            clearInterval(progessBarInterval);
            break;
          case 'ISSUES OUTSIDE OF GAP ANALYSIS':
            GLOBAL_TAG.value = 'reputation_risks';
            break;
          case 'CHICAGO REFERENCES USED FOR CURRENT GAP ANALYSIS':
            GLOBAL_TAG.value = 'current_gap_references';
            break;
          case 'CURRENT GAP ANALYSIS':
            GLOBAL_TAG.value = 'current_gap_analysis';
            company.value.research_status = 'Writing section on the current operations'
            progress_bar.value = 96
            break;
          case 'CURRENT SCORE':
            GLOBAL_TAG.value = 'current_gap_score';
            break;
          case 'CURRENT LABEL':
            GLOBAL_TAG.value = 'CUR_gap_label';
            break;
          case 'POTENTIAL GAP ANALYSIS':
            GLOBAL_TAG.value = 'potential_gap_analysis';
            company.value.research_status = 'Writing section on the potential'
            progress_bar.value = 97
            break;
          case 'CHICAGO REFERENCES USED FOR POTENTIAL GAP ANALYSIS':
            GLOBAL_TAG.value = 'potential_gap_references';
            break;
          case 'POTENTIAL SCORE':
            GLOBAL_TAG.value = 'potential_gap_score';
            break;
          case 'POTENTIAL LABEL':
            GLOBAL_TAG.value = 'POT_gap_label';
            break;
          case 'AVERAGED SCORE':
            GLOBAL_TAG.value = 'averaged_gap_score';
            break;
          case 'AVERAGED LABEL':
            GLOBAL_TAG.value = 'AVG_gap_label';
            break;
          case 'AVERAGED GAP ANALYSIS':
            GLOBAL_TAG.value = 'averaged_gap_analysis';
            company.value.research_status = 'Writing an overview'
            progress_bar.value = 98
            break;
          case 'INVESTMENT QUESTIONS':
            GLOBAL_TAG.value = 'investment_questions';
            company.value.research_status = 'Writing some investment questions'
            progress_bar.value = 99
            break;
          case 'CHICAGO REFERENCES':
            GLOBAL_TAG.value = 'references';
            break;
          case 'RESEARCH DATA':
            GLOBAL_TAG.value = 'research_data';
            break;
          case 'PRE ANALYSIS CAT':
            GLOBAL_TAG.value = 'pre_analysis_category';
            break;
          case 'PRE ANALYSIS COST':
            GLOBAL_TAG.value = 'pre_analysis_cost';
            break;
          case 'PRE ANALYSIS LEGAL':
            GLOBAL_TAG.value = 'pre_analysis_legal';
            break;
          case 'PRE ANALYSIS SCAMS':
            GLOBAL_TAG.value = 'pre_analysis_scams';
            break;
          case 'PRE ANALYSIS BOGO':
            GLOBAL_TAG.value = 'pre_analysis_bogo';
            break;
          case 'PRE ANALYSIS BUS TYPE':
            GLOBAL_TAG.value = 'pre_analysis_bus_type';
            break;
          case 'PRE ANALYSIS BUS IND':
            GLOBAL_TAG.value = 'pre_analysis_bus_industry';
            break;
          case 'PRE ANALYSIS BUS POTEN':
            GLOBAL_TAG.value = 'pre_analysis_bus_potential';
            break;
          case 'PRE ANALYSIS GAP POTEN':
            GLOBAL_TAG.value = 'pre_analysis_gap_potential';
            break;
          case 'START PROGRESSBAR':
            this.startProgressBar();
            break;
          case 'PRE ANALYSIS MISSION':
            GLOBAL_TAG.value = 'pre_analysis_analysis_mission';
            break;
          case 'CLASSIFICATION SCORE RANGES':
            GLOBAL_TAG.value = 'pre_analysis_classification_score_ranges';
            break;
          case 'END ANALYSIS':
            GLOBAL_TAG.value = 'end_analysis';
            break;

        }

        GLOBAL_DISPLAY_BUFFER_FLAG.value = false;

      }

      startProgressBar() {

        const researchStatusUpdates = [
          { status: 'classifying business category.', value: 86 },
          { status: 'analyzing product cost.', value: 87 },
          { status: 'analyzing product buyers.', value: 88 },
          { status: 'analyzing product use-cases.', value: 89 },
          { status: 'analyzing legal.', value: 90 },
          { status: 'analyzing customer reviews.', value: 91 },
          { status: 'analyzing mission.', value: 92 },
          { status: 'examining pricing strategies.', value: 93 },
          { status: 'determining business model.', value: 94 },
          { status: 'identifying business industry.', value: 95 },
          { status: 'finalizing & writing analysis below!', value: 96 }
        ];

        let updateIndex = 0;

        const updateResearchStatusSequentially = () => {
          if (reportVisible.value === true) {
            return; // Stop the timer if the report is visible
          }
          if (updateIndex < researchStatusUpdates.length) {
            const update = researchStatusUpdates[updateIndex];
            company.value.research_status = update.status;
            progress_bar.value = update.value;
            updateIndex++;
            setTimeout(updateResearchStatusSequentially, 2250);
          }
        };

        updateResearchStatusSequentially();

      }

      extractJSON(text: string): string[] {
        const regex = /({[^{}]*})/g;
        const jsonStrings: string[] = [];
        let match: RegExpExecArray | null;

        while ((match = regex.exec(text)) !== null) {
          jsonStrings.push(match[1]);
        }

        return jsonStrings;
      }

      routeChunk(selector, chunk) {

        // Must trim start for marked.parse
        chunk = chunk.trimStart();

        switch (selector) {
          case 'research_data':
            try {
              let jsonString = this.extractJSON(buffer)[0];

              if (jsonString) {
                let research_data = JSON.parse(jsonString);

                is_research_complete.value = research_data.is_complete;

                if (is_research_complete.value) {
                  clearInterval(longPullInterval);
                  clearInterval(progessBarInterval);
                }

              }
            } catch (error) {
              console.log(error)
            }
            break;
          case 'pre_analysis_category':
            company.value.research_status = 'Classifying business category...';
            progress_bar.value = 86
            break;
          case 'pre_analysis_cost':
            company.value.research_status = 'Analyzing product cost and buyers...';
            progress_bar.value = 87
            break;
          case 'pre_analysis_legal':
            company.value.research_status = 'Analyzing legal...';
            progress_bar.value = 88
            break;
          case 'pre_analysis_scams':
            company.value.research_status = 'Analyzing customer reviews...';
            progress_bar.value = 89
            break;
          case 'pre_analysis_bogo':
            company.value.research_status = 'Examining pricing strategies...';
            progress_bar.value = 90
            break;
          case 'pre_analysis_bus_type':
            company.value.research_status = 'Determining business model...';
            progress_bar.value = 91
            break;
          case 'pre_analysis_bus_industry':
            company.value.research_status = 'Identifying business industry...';
            progress_bar.value = 92
            break;
          case 'pre_analysis_gap_potential':
            company.value.research_status = 'Examining use cases...';
            progress_bar.value = 93
            break;
          case 'pre_analysis_analysis_mission':
            company.value.research_status = 'Identifying mission...';
            progress_bar.value = 94
            break;
          case 'pre_analysis_classification_score_ranges':
            company.value.research_status = 'Finalizing & writing analysis below!';
            progress_bar.value = 95
            break;
          case 'current_gap_analysis':
            company.value.gap_closing_category.CUR.analysis = marked.parse(chunk);
            progress_bar.value = 96
            showReport(true);
            break;
          case 'meta_data':
            try {
              parseJsonMetaData(JSON.parse(chunk))
            } catch (error) { 
              //
            }
            break;
          case 'reputation_risks': 
            if (chunk) {
              company.value.reputation_risks.analysis = marked.parse(chunk)
            }
            break;
          case 'current_gap_score':
            company.value.gap_closing_category.CUR.score = chunk;
            this.checkScore(chunk);
            break;
          case 'potential_gap_analysis':
            company.value.gap_closing_category.POT.analysis = marked.parse(chunk);
            break;
          case 'potential_gap_score':
            company.value.gap_closing_category.POT.score = chunk;
            this.checkScore(chunk);
            break;
          case 'CUR_gap_label':
            company.value.gap_closing_category.CUR.title = chunk;
            break;
          case 'POT_gap_label':
            company.value.gap_closing_category.POT.title = chunk;
            break;
          case 'AVG_gap_label':
            company.value.gap_closing_category.AVG.title = chunk;
            break;
          case 'averaged_gap_analysis':
            company.value.gap_closing_category.AVG.analysis = marked.parse(chunk);
            break;
          case 'averaged_gap_score':
            company.value.gap_closing_category.AVG.score = chunk;
            this.checkScore(chunk);
            break;
          case 'investment_questions':
            company.value.supplemental_data.investment_questions = marked.parse(chunk);
            break;
          case 'references':
            company.value.references = marked.parse(chunk);
            break;
        }

      }


      processInput(input) {

        let index = 0;

        while (index < input.length) {

          is_streaming.value = true;
          is_streaming_complete.value = false;

          let current_letter = input[index];

          if (this.isTagOpen === true && current_letter !== '☜') {
            this.currentTag = this.currentTag + current_letter;
          }

          if (this.isTagOpen === false && current_letter !== '☞') {
            this.contentBuffer = this.contentBuffer + current_letter;
            this.routeChunk(GLOBAL_TAG.value, this.contentBuffer);
          }

          // Place at flags bottom; so we don't include the fingers in the results
          if (current_letter === '☞') {
            this.contentBuffer = '';
            this.currentTag = '';
            this.isTagOpen = true;
          }

          // Finished Finding Tag
          if (current_letter === '☜') {
            this.isTagOpen = false;
            this.routeTag(this.currentTag);
          }

          index++;
        }

      }

    }
    const route = useRoute();
    const router = useRouter();

    let longPullInterval: number;
    let progessBarInterval: number;
    let buffer = '';
    let gciId = null;

    const {
      company_research_data,
      company,
      labelClasses,
      reportVisible,
      loadingBarVisible,
      GLOBAL_TAG,
      GLOBAL_DISPLAY_BUFFER_FLAG,
      is_research_complete,
      is_streaming,
      is_streaming_complete,
      search_name,
      search_website,
      buttonDisabled,
      abortController,
      progress_bar,
      counter,
      showReferences,
      flag_progress_bar_INFO,
      flag_progress_bar_PROD,
      flag_progress_bar_TARAUD,
      flag_progress_bar_BIPOCBEN,
      flag_progress_bar_PRODCOST,
      flag_progress_bar_NEWS,
      flag_progress_bar_EREVIEWS,
      flag_progress_bar_LEGAL,
      flag_progress_bar_UREVIEWS,
      flag_progress_bar_REVIEWS,
      feedbackReport,
      gap_scores,
      gap_scores_labels,
      report_exists_and_complete,
      report_timer,
      error_modal,
      error_modal_content,
      had_streaming,
    } = CompanyReportStates;

    const completed_research_count = computed(() => {
      return company_research_display_order.filter(key => company_research_data.value[key] !== null && company_research_data.value[key] !== undefined).length;
    });

    const getResearchStatus = async (company_id, isReportComplete=false) => {

      const data = await getStatusStream(company_id, false, isReportComplete)

      return data;

    }

    const getResearchAndStream = async (company_id) => {

      const data = getStatusStream(company_id, true);

      return data;

    }

    const getStatusStream = async (company_id, stream = false, isReportComplete=false) => {

      let data = await reportCompanyService.startCheckResearch(gciId, company_id, isReportComplete)
      let noResearchCount = 0;

      if (stream == true ) {
        is_streaming.value = true
        had_streaming.value = true
        is_streaming_complete.value = false
      }

      for (let researchData of data.research_data) {
        var type = researchData.type;
        var content = researchData.data;

        if (content) {

          if (type == 'TARAUD' || type == 'BIPOCBEN' || type == 'PRODCOST' ||  type == 'PROD') {
            if (content.includes("No research was returned")) noResearchCount++;    
          }

          if (type !== 'INFO') {
            const markedContent = await marked(content);

            company_research_data.value[type] = markedContent.replace('☞RESPONSE☜', '').replace('☞CHICAGO REFERENCES☜', 'References');

          } else {


            try {
              company_research_data.value[type] = JSON.parse(content.replace('☞RESPONSE☜', '').trim())
            } catch (error) {
              console.log(error)
            }
          }


          if (eval(`flag_progress_bar_${type}.value`) == false) {
            eval(`flag_progress_bar_${type}.value = true`)

            type = research_data_title_map[type]

            progress_bar.value += 5
            company.value.research_status = `Completed ${type} Research`
          }
        }


      }

      if (data.has_active_failure) {
        error_modal.value = true
        error_modal_content.value = "There was an error processing this company."
        return data;

      } else if (data.is_ready || isReportComplete) {

        is_research_complete.value = true

        clearInterval(longPullInterval)
        clearInterval(progessBarInterval)

        if (noResearchCount > 1) {
          error_modal.value = true
          error_modal_content.value = "I was unable to find enough information for analyis."
          return data;
        }

        if (stream === true) streamGapScore(company_id)

      } else {
        is_research_complete.value = false
        is_streaming.value = true
      }

      return data

    }

    const userStore = useUserStore();
    const userData = computed(() => userStore.getUser());

    let feedback = (show = false) => {
    
      feedbackReport.value = createSidetab('XGbUoIry', {
          hidden: {
              report_company_name: company.value.name,
              report_company_website: company.value.website,
              username: userData.value.username,
              url: window.location.href
          },
          autoClose: 2000,
          buttonText: 'Give Feedback',

      });

      if (show === true) feedbackReport.value.open();

    };

    const cleanup = () => {
      clearInterval(progessBarInterval)
      clearInterval(longPullInterval)
      if (feedbackReport.value) {
        feedbackReport.value.unmount()
      }
      abortController.value.abort();
      abortController.value = new AbortController();
    };

    const clearReport = () => {

      showReport(false);

      resetData(company.value, getCompanyDefaults())
      resetData(company_research_data.value, company_research_data_defaults)

      GLOBAL_TAG.value = '';
      GLOBAL_DISPLAY_BUFFER_FLAG.value = false;

      // Set company name & website to current search
      company.value.name = search_name.value
      company.value.website = search_website.value
      progress_bar.value = 0
      counter.value = 0
      report_timer.value = 0

      flag_progress_bar_INFO.value = false
      flag_progress_bar_PROD.value = false
      flag_progress_bar_TARAUD.value = false
      flag_progress_bar_BIPOCBEN.value = false
      flag_progress_bar_PRODCOST.value = false
      flag_progress_bar_NEWS.value = false
      flag_progress_bar_EREVIEWS.value = false
      flag_progress_bar_UREVIEWS.value = false
      flag_progress_bar_LEGAL.value = false
      flag_progress_bar_REVIEWS.value = false

      // Set research flag back to start
      is_research_complete.value = false
      is_streaming.value = false;
      is_streaming_complete.value = false;
      had_streaming.value=false;

      gap_scores_labels.value = []
      gap_scores.value = []
      
      for (const key in labelClasses.value) {
        labelClasses.value[key] = 'is-loading';
      }

      cleanup()

    };

    const showReport = (show = false) => {

      if (show) {
        reportVisible.value = true;
        loadingBarVisible.value = false;
        return;
      }

      reportVisible.value = false;
      loadingBarVisible.value = true;
      showReferences.value = false;

    }

    const handleInitialNavigation = () => {
      const tab = route.query.tab as string;
      const section = route.query.section as string;
      if (tab && section) {
        nextTick(() => {
          handleGoToTab(tab, section);
        });
      }
    };


    const handleGoToTab = (tabName: string, sectionId: string, attempts = 5) => {

      const tabButton = document.querySelector(`#${tabName}-tab`);
      if (tabButton) {
        (tabButton as HTMLElement).click();
      }

      const tryScrollToSection = () => {
        const sectionElement = document.getElementById(sectionId);

        if (sectionElement) {
          const headerOffset = 100; 
          const elementPosition = sectionElement.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });

        } else if (attempts > 0) {
          setTimeout(() => tryScrollToSection(), 300);
          attempts--;
        } 
      };

      setTimeout(tryScrollToSection, 300);
    };

    const goToTabListener = (event: Event) => {
      const customEvent = event as CustomEvent<{tabName: string, sectionId: string}>;
      handleGoToTab(customEvent.detail.tabName, customEvent.detail.sectionId);
    };

    const updateReputationRiskLabels = (json) => {

      if ( 
        ( json.money_scams && json.money_scams.has_complaints) ||
        ( json.user_reviews && json.user_reviews.is_sentiment_negative )
      )
      {
        if (!company.value.reputation_risks.flags.includes('user_reviews_flag')) {
          company.value.reputation_risks.flags.push('user_reviews_flag');
        }
      }

      if ( 
        (json.legal_analysis && json.legal_analysis.has_lawsuits ) ||
        (json.legal_analysis && json.legal_analysis.has_discrimination_complaints)
      )
      {
        if (!company.value.reputation_risks.flags.includes('legal_flag')) {
          company.value.reputation_risks.flags.push('legal_flag');
        }
      }

      if ( json.employee_reviews && json.employee_reviews.is_sentiment_negative ) {
        if (!company.value.reputation_risks.flags.includes('employee_reviews_flag')) {
          company.value.reputation_risks.flags.push('employee_reviews_flag');
        }
      }

    }

    const parseJsonMetaData = (json) => {

      updateReputationRiskLabels(json)

      const graphData = updateCompanyGapGraphScores(json)

      if (Array.isArray(graphData.scores) && Array.isArray(graphData.labels)) {

        gap_scores_labels.value = graphData.labels

        gap_scores.value = [{
          name: ['Alignment'],
          data: graphData.scores
        }];

      } else {
        
        // graphData.scores.value = []
        gap_scores.value = []
        gap_scores_labels.value = []

      }

    }


    const parseCachedGapClosingReport = (reportData) => {

      for (let raw_data of reportData.raw_data) {
        if (raw_data.type === 'MET') {
          company.value.raw_data = [ raw_data ]
          break;
        }
      }

      company.value.name = search_name.value = reportData.company.name;
      company.value.website = search_website.value = reportData.company.website;

      // Gap Closing Categories
      for (let gap_closing_category of reportData.company_gap_closing_categories) {
        let data = {
          score: gap_closing_category.score,
          label: gap_closing_category.label,
          title: setGapTitle(gap_closing_category.label),
          analysis: gap_closing_category.analysis
        }

        company.value.gap_closing_category[gap_closing_category.type] = data;
        labelClasses.value[gap_closing_category.type + '_gap_label'] = setGapCSS(gap_closing_category.label);

        // Markdown the required content
        company.value.gap_closing_category.AVG.analysis = marked(company.value.gap_closing_category.AVG.analysis)
        company.value.gap_closing_category.CUR.analysis = marked(company.value.gap_closing_category.CUR.analysis)
        company.value.gap_closing_category.POT.analysis = marked(company.value.gap_closing_category.POT.analysis)
      }

      for (let supplemental_data of reportData.company_gap_closing_supplemental_data) {
        if (supplemental_data.type == 'INV') company.value.supplemental_data.investment_questions = marked(supplemental_data.content);
      }

      company.value.references_count = countLinksInReferences(reportData.references, reportData.company.website);

      company.value.references = reportData.references ? marked(reportData.references) : '';

      if (company.value.raw_data && company.value.raw_data[0] && company.value.raw_data[0].raw_json) {
      
        parseJsonMetaData(company.value.raw_data[0].raw_json)
      
      }
      
      if ( reportData.reputation_risks ) {

        if (reportData.reputation_risks.flags?.length > 0) company.value.reputation_risks.flags = reportData.reputation_risks.flags

        if (reportData.reputation_risks.analysis && reportData.reputation_risks.analysis.trim() !== '') {
          company.value.reputation_risks.analysis = marked(reportData.reputation_risks.analysis);
        }

      } else {
        company.value.reputation_risks.flags = [];
        company.value.reputation_risks.analysis = '';
      }

      showReport(true);

    }

    function countLinksInReferences(references: string, company_website: string): number {
    if (!references) {
      return 0;
    }

    const referenceLines = references.split('\n');
    const normalizedCompanyWebsite = company_website.replace(/^(https?:\/\/)?(www\.)?/, '');
    const uniqueCompanyWebsiteLinks = new Set();
    
    // Count company links only once
    const linkCount = referenceLines.reduce((count, line) => {
      const match = line.match(/\bhttps?:\/\/(www\.)?([^/]+)(\/\S*)?/i);
      if (match) {
        const hostname = match[2].replace(/\bhttps?:\/\/(www\.)?([^/]+)(\/\S*)?/i, '');
        if (hostname.endsWith(normalizedCompanyWebsite)) {
          uniqueCompanyWebsiteLinks.add(hostname);
          return count;
        } else {
          return count + 1;
        }
      }
      return count;
    }, 0);

    return linkCount + (uniqueCompanyWebsiteLinks.size > 0 ? 1 : 0);
  }

    const startReport = async (company_id: number | false = false, company_name: string | false = false, company_website: string | false = false) => {

      let company_data;

      if (company_id) {

        company_data = await reportCompanyService.getCompany(company_id)

        if (company_data.name) 
          company.value.name = search_name.value = company_data.name;
        
        if (company_data.website)   
        company.value.website = search_website.value = company_data.website;
          
        if (company_data.id)  
        company.value.id = company_data.id
        

        runReport(company_id)

        return
      }

      if (!company_name || !company_website) throw new Error('Company name and website must be provided');

      search_name.value = company_name;
      search_website.value = company_website;

      company_id = await reportCompanyService.getCompanyId(company_name, company_website);
      

      if (company_id) {

        runReport(company_id)

        return

      } else {

        throw new Error('Company ID could not be found');

      }

    };

    const startReportCreation = async (companyId) => {


        if (!gciId) gciId = await reportCompanyService.createGapClosingReport(companyId);

        let data = await getResearchAndStream(companyId)

        if (data.has_active_failure) {
          error_modal.value = true
          error_modal_content.value = "There was an error processing this company."
          clearInterval(longPullInterval);
          clearInterval(progessBarInterval);
          return;
        }

        if (data.is_ready) return;

        longPullInterval = setInterval(async function () {

            data = await getResearchAndStream(companyId)

        }, 3000);

        progessBarInterval = setInterval(updateProgressBar, 1000);

    };

    function updateProgressBar() {

      report_timer.value += 1

      // show alert
      if ( report_timer.value == 120) {
        error_modal.value = true
        error_modal_content.value = "The research is taking a bit longer than expected."
        clearInterval(longPullInterval);
        clearInterval(progessBarInterval);
      }

      if (counter.value < 40) {
          progress_bar.value += 1
      } else {
          if (progress_bar.value < 86 && progress_bar.value > 39) {
              if (counter.value % 3 === 0) {
                  company.value.research_status = 'AI Agents are Still Working'
              } else if (counter.value % 10 === 5) {
                  company.value.research_status = 'AI Agents are Still Summarizing'
              } else if (counter.value % 10 === 0) {
                  company.value.research_status = 'AI Agents are Discovering More Content'
              }
          }
      }

      switch (progress_bar.value) {
          case 2:
              company.value.research_status = 'Initating AI Research Agents'
              break
          case 5:
              company.value.research_status = 'AI Agents are searching'
              break
          case 10:
              company.value.research_status = 'AI Agents are Reading Content'
              break;
          case 14:
              company.value.research_status = 'AI Agents are Still Reading Content'
              break;
          case 20:
              company.value.research_status = 'AI Agents are Starting Reports'
              break;
          case 24:
              company.value.research_status = 'AI Agents are Writing Reports'
              break;
          case 30:
              company.value.research_status = 'AI Agents are Still Writing Reports'
              break;
          case 34:
              company.value.research_status = 'AI Agents are Discovering More Content'
              break;
          case 40:
              company.value.research_status = 'AI Agents are Still Discovering More Content'
              break;
          default:
      }

      counter.value += 1

      }


   
    const getParse = async (company_id: number) => {

      setTimeout(async () => {
        const [reportData, doesReportExists, isReportComplete, reportId] = await reportCompanyService.getGapClosingReport(company_id)

        if (reportData) {
          parseCachedGapClosingReport(reportData)
        }

        getResearchStatus(company_id)

        showReferences.value = true
      }, 10000);

    };

    const runReport = async (company_id: number) => {

      if (!company_id) throw new Error('No company id provided');

      userService.saveSearchHistory(company_id)

      const [reportData, doesReportExists, isReportComplete, reportId] = await reportCompanyService.getGapClosingReport(company_id)

      if (reportId) gciId = reportId;

      if (reportData && reportData.company && reportData.company.name) {
          company.value.name = search_name.value = reportData.company.name
        }

      if (reportData && reportData.company && reportData.company.website) {
          company.value.website = search_website.value =  reportData.company.website
      }

      if (doesReportExists == true && isReportComplete == true) {
        report_exists_and_complete.value = true
      } else {
        report_exists_and_complete.value = false
      }

      if (doesReportExists == false || (doesReportExists == true && isReportComplete == false)) {

        clearReport()
        
        startReportCreation(company_id)

        return

      }

      if (reportData) {

        parseCachedGapClosingReport(reportData)

      }

      getResearchStatus(company_id, isReportComplete)

      showReferences.value = true

    };


    const generateStream = async (endpoint: string, method: string, data: any = null) => {
      
      is_streaming.value = true;
      is_streaming_complete.value = false;

      let fetchOptions: RequestInit = {
        method: method,
        signal: abortController.value.signal,
      };

      if (method !== 'GET' && data) {
        fetchOptions.body = JSON.stringify(data);
      }

      try {
        const response = await fetch(endpoint, fetchOptions);

        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

        if (!response.body) throw new Error('No response body');

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let reading = true;
        let processor = new TagProcessor();

        while (reading) {
          const { done, value } = await reader.read();

          if (done || GLOBAL_TAG.value === 'end_analysis') {
            reading = false;
            break;
          }

          const chunk = decoder.decode(value, { stream: true });

          if (chunk) processor.processInput(chunk);
        }

        is_streaming.value = false;
        is_streaming_complete.value = true;

        return { status: 'done' };
      } catch (error) {
        abortController.value.signal.aborted ? console.log("Fetch aborted") : console.error("Error during fetch:", error);
      } finally {
        abortController.value.abort();
        abortController.value = new AbortController();
      }
    };

    const streamGapScore = async (company_id: number) => {

      if (is_research_complete.value == false) return false;    

      buffer = '';

      buttonDisabled.value = true;
      abortController.value = new AbortController();

      let endpoint = `${GCI_SSE_ULR}/?company_id=${company_id}`;
      let regather;

      try {
        const response = await generateStream(endpoint, 'GET');
        if (response && response.status === "done") {
          if (company.value.references && company.value.website) {
            showReferences.value = true;
            company.value.references_count = countLinksInReferences(company.value.references, company.value.website);
          }
          regather = await getParse(company_id)
        }
      } catch (error) {
        console.error('Error fetching gap index:', error);
      } finally {
        buttonDisabled.value = false;
      }
    };


    watch(() => route.fullPath, () => {
      cleanup();
    });

    watch( () => route.query, () => {
        handleInitialNavigation();
      }
    );
    
    router.beforeEach((to, from, next) => {
      cleanup();
      next();
    });

    onBeforeUnmount(() => {
      clearReport();
      window.removeEventListener('goToTab', goToTabListener);
    });

    onMounted(async () => {

      company.value.name = route.query.company_name || false;
      company.value.website = route.query.company_website || false;
      company.value.id = route.params.companyId;

      if (!company.value.id) {
        if (!company.value.name || !company.value.website) {
          router.push({ name: 'CVDashboard' });
          return;
        }
      }

      await startReport(company.value.id, company.value.name, company.value.website);

      handleInitialNavigation();

      window.addEventListener('goToTab', goToTabListener);

      feedback();
    
    });

    return {
      GLOBAL_TAG,
      company,
      company_research_data_defaults,
      company_research_display_order,
      company_research_data,
      research_data_title_map,
      // getCompanyDefaults(),
      label_defaults,
      gap_scores,
      gap_scores_labels,
      progress_bar,
      is_research_complete,
      is_streaming,
      is_streaming_complete,
      reportVisible,
      report_exists_and_complete,
      report_timer,
      error_modal,
      error_modal_content,
      had_streaming,
      completed_research_count,
      handleGoToTab,
      companyChart: {
        chart: {
          height: "100%",
          type: "bar", 
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            borderRadiusApplication: 'end',
            distributed: true
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["#ffffff"],
        },
        labels: ["Education Gaps", "Low Income Gaps", "Middle Class Gaps", "Community Gaps", "Environmental Gaps", "No Ethics Concerns", "Pricing"],
        yaxis: {
          show: true,
          tickAmount: 10,
          min: 0,
          max: 10,
          forceNiceScale: false,
          labels: {
            formatter: function (val) {
              if (val === 10) {
                return "Aligns";
              }
              if (val === 7) {
                return "High Potential";
              }
              if (val === 4) {
                return "Low Potential";
              }
              if (val === 1) {
                return "Does Not Align";
              }
              return "";
            }
          }
        },
        tooltip: {
          style: {
            fontSize: "14px",
            fontFamily: "Red Hat Display, sans-serif",
          },
          y: {
              formatter: function (val, index) {

                let label = gap_scores_labels.value[0][index.dataPointIndex];

                let response = ''

                if (val == 10) {
                  response += "Aligns";
                }

                if (val < 5 && val != 1) {
                  response += "Has potential,";
                }

                if (label !== false) {
                  if (typeof label === 'string' && val != 7 ) {
                    label = label.toLowerCase();
                  }
                  if (index.dataPointIndex === 5 || index.dataPointIndex === 6) {
                    response += "Concerns (" + label + ")"
                  } else if (label === 1) {
                    response = "Not in Business Strategy"
                  } else {
                    response += label
                  }
                } else {
                  if (val <= 1 && index.dataPointIndex != 6) {
                    response = "Not in Business Strategy"
                  }
                  if (val <= 1 && index.dataPointIndex == 6) {
                    response = "Pricing concerns"
                  }
                  if (index.dataPointIndex === 5 ) {
                    response = "No concerns found"
                  }
                }
                
                return response

              }
            }
        
        },
        fill: {
          opacity: 1,
        },
        colors: ["#06B48A", "#6FD3F7", "#F1421B", "#6560F0", "#F3C44C", "#d10aba", "#4D4D4D"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false, // Hide the legend
        },
      },
    };
  },
});
</script>
<style scoped>
.nav-link:disabled {
  color: grey !important;
  cursor: not-allowed;
  position: relative;
}
.leads-details-tabs .nav.nav-tabs .nav-item .nav-link {
  height: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
</style>