<template>
    <BreadCrumb PageTitle="Organization Settings" />
    <OrganizationSettings />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import OrganizationSettings from "../../components/Settings/OrganizationSettings.vue";
  
  export default defineComponent({
    name: "OrganizationSettingsPage",
    components: {
      BreadCrumb,
      OrganizationSettings,
    },
  });
  </script>