<template>
    <div class="d-flex align-items-center">
      <select v-model="selectedStatus" @change="handleStatusChange" class="form-select form-select-sm shadow-none fs-13 text-black" style="width: 130px">
        <option value="" selected>Select</option>
        <option value="Pass">Pass</option>
        <option value="In Progress">In Progress</option>
        <option value="1st Meeting">1st Meeting</option>
        <option value="2nd Meeting">2nd Meeting</option>
        <option value="Invested">Invested</option>
      </select>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref } from 'vue';
  import {companiesEmits} from '@/components/Companies/CompaniesUtils.js'

  export default defineComponent({
    name: 'CompanyActionsCell',
    props: ['company'],
    emits: companiesEmits,
    setup(props, { emit }) {
      const selectedStatus = ref('');
  
      const handleStatusChange = () => {
        emit('update-status', { id: props.company.id, status: selectedStatus.value });
        selectedStatus.value = ''; 
      };
  
      return {
        selectedStatus,
        handleStatusChange
      };
    }
  });
  </script>