import { API_URL, makeAuthenticatedRequest } from '@/utils/apiUtils';

export const inboundService = {
  async getAllCompanies(page) {
    let url = `${API_URL}/company/?all=True`;

    if (page) {
      url += `&page=${page}`;
    }

    return await makeAuthenticatedRequest(url, 'GET');
  },
  async getInboundCompanies(page, filters) {
    let url = `${API_URL}/company/?inbound=True`;

    if (filters) {
      url += `&${filters}`;
    }

    if (page) {
      url += `&page=${page}`;
    }

    return await makeAuthenticatedRequest(url, 'GET');
  },
};