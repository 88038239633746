<template>
    <div :class="`card mb-25 rounded-0 bg-white letter-spacing ${global_tag == content_id ? 'border border-1 border-info' : 'border-0'}`" v-if="content && content_id && title" :id="content_id">
        <div
            class="card-head bg-white d-md-flex align-items-center justify-content-between pt-25 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
            <h5 class="card-title fw-bold mb-0">{{ title }}</h5>
        </div>
        <div class="card-body pt-0 pb-15 pb-sm-20 pb-md-25 pb-lg-30 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30"
            v-html="content">
        </div>
    </div>
</template>

<script>
export default {
    props: ['title', 'content', 'content_id', 'global_tag'],
};
</script>