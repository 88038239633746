<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-5 p-sm-20 p-md-25">
            <div class="d-md-flex mt-0 mb-0 justify-content-between align-items-center">
                <div class="pagination-area">
                    <p class="mb-0 text-paragraph">
                        Showing <span class="fw-bold">{{ (currentPage - 1) * pageSize + 1 }}</span> - <span class="fw-bold">{{ Math.min(currentPage * pageSize, resultsCount) }}</span> out of <span class="fw-bold">{{ resultsCount }}</span> total
                    </p>
                </div>
                <nav>
                    <ul class="pagination mb-0 justify-content-end">
                        <li class="page-item" :class="{ disabled: currentPage === 1 }">
                            <a class="page-link shadow-none fw-medium" @click.prevent="goToPage(currentPage - 1)" href="#">Previous</a>
                        </li>
                        <li v-for="page in pages" :key="page" class="page-item" :class="{ active: currentPage === page }">
                            <a class="page-link shadow-none fw-medium" @click.prevent="goToPage(page)" href="#">{{ page }}</a>
                        </li>
                        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                            <a class="page-link shadow-none fw-medium" @click.prevent="goToPage(currentPage + 1)" href="#">Next</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ['resultsCount', 'companiesDisplayed', 'nextPage', 'previousPage', 'currentPage', 'pageSize'],
  computed: {
    totalPages() {
      const companiesPerPage = this.pageSize > 0 ? this.pageSize : 1;
      return Math.ceil(this.resultsCount / companiesPerPage);
    },
    pages() {
      const pages = [];
      const startPage = Math.max(2, this.currentPage - 2);
      const endPage = Math.min(this.totalPages - 1, this.currentPage + 2);

      pages.push(1);
      if (startPage > 2) {
        pages.push('...');
      }
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < this.totalPages - 1) {
        pages.push('...');
      }
      if (this.totalPages > 1) {
        pages.push(this.totalPages);
      }
      return pages;
    },
  },
  methods: {
    goToPage(page) {
      if (page !== '...' && page > 0 && page <= this.totalPages) {
        this.$emit('page-changed', page);
        this.updateUrl(page);
      }
    },
    updateUrl(page) {
      this.$router.push({ query: { ...this.$route.query, page } });
    },
  },
};
</script>