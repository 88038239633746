<template>
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <h4 class="text-black fw-bold mb-0 text-center">
            Research a Company
          </h4>
          <p class="text-center mt-10 text-muted">
            Our AI powered meticulously researches businesses, highlighting areas ripe for improvement to maximize
            potential.
          </p>
          <form @submit.prevent="handleSubmit">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Company Name
              </label>
              <input type="text" v-model="companyName" class="form-control shadow-none rounded-0 text-black"
                placeholder="e.g. Kapor Center" required />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Company Website
              </label>
              <input type="url" v-model="companyWebsite" class="form-control shadow-none rounded-0 text-black"
                placeholder="e.g. kaporcenter.com" required />
            </div>

            <button
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success d-block w-100"
              type="submit">
              Research Company
            </button>

            <div class="mt-50" v-if="searchHistory">
              <span
                class="d-block or text-muted text-center mt-15 mb-15 mt-md-20 mb-md-20 fs-md-15 fs-lg-16 position-relative z-1 lh-1"><span
                  class="d-inline-block bg-white">Recent Searches</span></span>
              <div class="list-group" v-for="(search, index) in searchHistory" :key="index">
                <a :href="`/company-report/${search.company.id}`" class="list-group-item list-group-item-action mb-2"
                  aria-current="true">
                  <div class="d-flex w-100 justify-content-between">
                    <div class="mt-2 mb-2">
                      <h6 class="fw-bold mb-0">{{ search.company.name }}</h6>
                      <small class="text-body-secondary">{{ formatTimeAgo(search.updated_at) }}</small>
                    </div>
                    <img v-if="search.company.logo" :src="search.company.logo" class="rounded border p-2 "
                      :alt="search.company.name" width="55px" height="55px" />
                  </div>
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { userService } from '@/api/userService'
import { formatDistanceToNow } from 'date-fns';
import { event } from 'vue-gtag'

export default {
  name: "SearchForm",
  setup() {
    const companyName = ref('');
    const companyWebsite = ref('');
    const searchHistory = ref([]);

    const fetchSearchHistory = async () => {
      try {
        const response = await userService.getSearchHistory();
        if (response.status === 200) {
          searchHistory.value = response.data;
        } else {
          console.error('Failed to fetch recent reports');
        }
      } catch (error) {
        console.error('Error fetching recent reports:', error);
      }
    };

    const formatTimeAgo = (dateString) => {
      return formatDistanceToNow(new Date(dateString), { addSuffix: true });
    };

    const handleSubmit = () => {
      event('search_submit', { 
        event_category: 'Search',
        event_label: 'Get Gap Index',
        company_name: companyName.value,
        company_website:  companyWebsite.value
      });
      const url = `/company-report/?company_name=${companyName.value}&company_website=${companyWebsite.value}`;
      window.location.href = url;
    };

    onMounted(() => {
      fetchSearchHistory();
    });

    return {
      companyName,
      companyWebsite,
      searchHistory,
      formatTimeAgo,
      handleSubmit
    };
  }
};


</script>