<template>
    <LogoutContent />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import LogoutContent from "../../components/Authentication/LogoutContent.vue";
  
  export default defineComponent({
    name: "LogoutPage",
    components: {
      LogoutContent,
    },
  });
  </script>