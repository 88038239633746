<template>
    <i class="flaticon flaticon-like fs-18 lh-1 position-relative top-2 me-3"></i>
    <i class="flaticon flaticon-dislike fs-18 lh-1 position-relative top-2"></i>
</template>
<script>
import {companiesEmits} from '@/components/Companies/CompaniesUtils.js'

export default {
  props: ['company'],
  emits: companiesEmits,
};
</script>