<template>
  <BreadCrumb PageTitle="Privacy Policy" />
  <DeleteAccount />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import DeleteAccount from "../../components/Settings/DeleteAccount.vue";

export default defineComponent({
  name: "DeleteAccountPage",
  components: {
    BreadCrumb,
    DeleteAccount,
  },
});
</script>