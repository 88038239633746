<template>
  <SearchForm />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SearchForm from "../../components/Search/SearchForm.vue";

export default defineComponent({
  name: "SearchPage",
  components: {
    SearchForm,
  },
});
</script>