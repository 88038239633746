<template>
  <header
    :class="[
      'header-area bg-white text-center text-md-start pt-15 pb-15 ps-15 pe-15 ps-md-20 pe-md-20 pe-lg-30 transition mb-25 position-fixed',
      { sticky: isSticky },
    ]"
    id="header"
  >
    <div class="row align-items-center">
      <div class="col-xl-4 col-lg-5 col-md-6">
        <div
          class="header-left-side justify-content-center justify-content-md-start d-flex align-items-center"
        >
          <button
            class="header-burger-menu transition position-relative lh-1 bg-transparent p-0 border-0"
            id="header-burger-menu"
            @click="stateStoreInstance.onChange"
          >
            <i class="flaticon-menu-3"></i>
          </button>
          <form class="search-box" @submit.prevent="handleSubmit">
            <div class="input-group">
              <input type="url" v-model="companyWebsite" class="form-control shadow-none rounded-0 border-0 text-black me-1"
                placeholder="Company Website" required />
              
              <input type="text" v-model="companyName" class="form-control shadow-none rounded-0 border-0 text-black me-1"
                placeholder="Company Name" required />

              <button
              class="default-btn position-relative transition border-0 fw-medium text-white pt-8 pb-8 ps-15 pe-15 pt-md-12 pb-md-12 ps-md-20 pe-md-20 text-decoration-none"
              type="submit">
                Research
                <i
                  class="flaticon-search-interface-symbol position-relative ms-5 top-1"
                ></i>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="col-xl-8 col-lg-7 col-md-6">
        <div
          class="header-right-side d-flex align-items-center justify-content-center justify-content-md-end"
        >
          <LightDarkSwtichBtn />

          <div class="dropdown notification-dropdown" v-if="canAccessFeature('notifications')">
            <button
              class="dropdown-toggle p-0 position-relative bg-transparent border-0 transition lh-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="flaticon-bell-2"></i>
              <span
                class="dot-badge position-absolute fw-bold rounded-circle text-white bg-primary d-inline-block"
              >
                3
              </span>
            </button>
            <div
              class="dropdown-menu rounded-0 bg-white border-0 start-auto end-0"
            >
              <div
                class="title d-flex align-items-center justify-content-between"
              >
                <span class="text-black fw-bold"
                  >Notifications <span class="text-muted">(03)</span></span
                >
                <router-link
                  to="/notifications"
                  class="text-decoration-none link-btn transition text-primary fw-medium"
                >
                  Clear All
                </router-link>
              </div>
              <ul class="ps-0 mb-0 list-unstyled dropdown-body">
                <li class="text-muted position-relative">
                  <div
                    class="icon rounded-circle position-absolute text-center"
                  >
                    <i class="ph-bold ph-target"></i>
                  </div>
                  <span class="d-block text-black-emphasis"
                    >You have a new 
                    <strong class="fw-semibold">Precision Pick</strong></span
                  >
                  2 hrs ago
                  <router-link
                    to="/ai-precision-picks"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></router-link>
                  <span
                    class="unread d-inline-block rounded-circle bg-primary position-absolute w-10 h-10"
                  ></span>
                </li>
                <li class="text-muted position-relative">
                  <div
                    class="icon rounded-circle position-absolute text-center"
                  >
                    <i class="ph-bold ph-user-plus"></i>
                  </div>
                  <span class="d-block text-black-emphasis">
                    <strong class="fw-semibold">A new user</strong> added in
                    Kapor.ai
                  </span>
                  3 hrs ago
                  <router-link
                    to="/"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></router-link>
                  <span
                    class="unread d-inline-block rounded-circle bg-primary position-absolute w-10 h-10"
                  ></span>
                </li>
                <li class="text-muted position-relative">
                  <div
                    class="icon rounded-circle position-absolute text-center"
                  >
                    <i class="ph-bold ph-envelope"></i>
                  </div>
                  <span class="d-block text-black-emphasis">
                    You have new
                    <strong class="fw-semibold">inbound leads</strong>
                  </span>
                  1 day ago
                  <router-link
                    to="/inbound"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></router-link>
                </li>
              </ul>
              <div class="text-center dropdown-footer">
                <router-link
                  to="/notifications"
                  class="link-btn text-decoration-none text-primary position-relative d-inline-block transition fw-medium fw-medium"
                >
                  See All Notifications
                </router-link>
              </div>
            </div>
          </div>
          <div class="dropdown profile-dropdown" v-if="userProfile">
            <button
              class="dropdown-toggle text-start fs-14 text-black-emphasis d-flex align-items-center p-0 position-relative bg-transparent border-0 transition lh-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
          
              <!-- <img v-if="userProfile.profile_image"
                :src="userProfile.profile_image ? userProfile.profile_image : require('../../assets/images/admin.jpg')"
                class="rounded"
                width="44"
                height="44"
                alt="admin"
              /> -->
              <span class="title d-none d-lg-block ms-10 ms-lg-15">
                <span class="d-block fw-bold mb-5 mb-md-8">
                  <template v-if="userData.first_name">{{ userData.first_name }} 
                    <template v-if="userData.last_name">{{ userData.last_name }}</template>
                  </template>
                  <template v-else>{{ userData.username }}</template>
                </span>
                <span class="text-body-emphasis fw-semibold fs-13">{{ userProfile.is_organization_admin ? 'Admin' : userProfile.role.charAt(0).toUpperCase() + userProfile.role.slice(1).toLowerCase() }}</span>
              </span>
            </button>
            <div
              class="dropdown-menu rounded-0 bg-white border-0 start-auto end-0"
            >
              <ul class="ps-0 mb-0 list-unstyled dropdown-body">
                <li
                  class="text-body-secondary fw-semibold transition position-relative"
                  v-if="canAccessFeature('accountSettings')"
                >
                  <i class="flaticon-user-2"></i>
                  My Account
                  <router-link
                    to="/account-settings"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></router-link>
                </li>
                <li
                  class="text-body-secondary fw-semibold transition position-relative"
                  v-if="canAccessFeature('organizationSettings')"
                  >
                  <i class="flaticon-setting"></i>
                  Org Settings
                  <router-link
                    to="/organization-settings"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></router-link>
                </li>
                <li
                  class="text-body-secondary fw-semibold transition position-relative"
                >
                  <i class="flaticon-warning"></i>
                  Support
                  <a href="https://dbt9q0ovf1s.typeform.com/to/lweeQ2nM" target="_blank" class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none">
                  </a>
                </li>
                <li
                  class="text-body-secondary fw-semibold transition position-relative"
                >
                  <i class="flaticon-logout"></i>
                  Logout
                  <router-link
                    to="/logout"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from "vue";
import LightDarkSwtichBtn from "./LightDarkSwtichBtn.vue";
import stateStore from "@/stores/stateStore";
import { useUserStore } from '@/stores/userStore';
import { canAccessFeature } from '@/utils/featuresUtils';
import { event } from 'vue-gtag'

export default defineComponent({
  name: "MainHeader",
  components: {
    LightDarkSwtichBtn,
  },
  setup() {
    const companyName = ref('');
    const companyWebsite = ref('');
    const stateStoreInstance = stateStore;
    const isSticky = ref(false);

    const userStore = useUserStore();
    const userProfile = computed(() => userStore.getProfile());
    const userData = computed(() => userStore.getUser());

    const handleSubmit = () => {
      event('search_submit', { 
        event_category: 'Search',
        event_label: 'Get Gap Index',
        company_name: companyName.value,
        company_website:  companyWebsite.value
      });
      const url = `/company-report/?company_name=${companyName.value}&company_website=${companyWebsite.value}`;
      window.location.href = url;
    };

    onMounted(() => {
      window.addEventListener("scroll", () => {
        let scrollPos = window.scrollY;
        isSticky.value = scrollPos >= 100;
      });
    });

    return {
      companyName,
      companyWebsite,
      handleSubmit,
      isSticky,
      stateStoreInstance,
      userProfile,
      userData,
      canAccessFeature
    };
  },
});
</script>

<style>
#app .header-area .header-left-side .search-box{
 width: 100%
}
</style>