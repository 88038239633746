<template>
<div class="modal contentModalScrollable" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Submission Content</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <!-- Use v-html to render the formatted submission content -->
          <div v-html="formattedSubmission"></div>
        </div>

        <div class="modal-footer">

          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

        </div>

      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

// Define the expected structure of the company prop
interface Company {
  submission?: string;
}

export default defineComponent({
  name: "CompanyModal",
  props: {
    company: {
      type: Object as () => Company,
      default: () => ({})
    }
  },
  computed: {
    formattedSubmission(): string {
      let response = this.company?.submission;

      if (!response) {
        return '';
      }
      const entities = {
        '&lt;': '<',
        '&gt;': '>',
        '&amp;': '&',
        '&quot;': '"',
        '&#39;': "'"
      };
    
      response = response.replace(/&[#\w]+;/g, entity => entities[entity] || entity);

      // Create a temporary DOM element
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = response;

      // Function to recursively process text nodes
      function processTextNodes(node) {
        if (node.nodeType === Node.TEXT_NODE) {
          const trimmedContent = node.textContent.trim().replace(/\n/g, '<br>');
          if (trimmedContent) {
            const fragment = document.createRange().createContextualFragment(trimmedContent);
            node.parentNode.replaceChild(fragment, node);
          } else {
            node.parentNode.removeChild(node);
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          Array.from(node.childNodes).forEach(processTextNodes);
        }
      }

      // Process the content
      processTextNodes(tempDiv);

      // Remove any empty text nodes
      tempDiv.normalize();

      // Return the cleaned HTML
      return tempDiv.innerHTML;

    }
  }
});
</script>
